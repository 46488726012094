<template>
    <a-modal v-model="filterVisible" title="数据筛选设置" :footer="null" :centered="true">
        <a-checkbox-group v-model="selectedConditions">
            <a-row v-for="item in conditions" :key="item.name">
                <a-col :span="24">
                    <a-checkbox :value="item.name">{{item.title}}</a-checkbox>
                </a-col>
            </a-row>
        </a-checkbox-group>
        <div class="condition">
            <div class="h2">满足隐藏条件的整行整列</div>
            <a-row :gutter="[16,16]">
                <a-col :span="24">
                    <a-select default-value="0" v-model="otherSelected" style="width: 100%">
                        <a-select-option :value="item.value" :key="item.value" v-for="item in otherConditions">
                            {{item.name}}
                        </a-select-option>
                    </a-select>
                </a-col>
            </a-row>

            <a-row :gutter="[16,16]">
                <a-col :span="11">
                    <div class="label">A:</div> <a-input-number style="width:170px;" v-model="numberA" :disabled="otherSelected===0" />
                </a-col>
                <a-col :span="2">
                    
                </a-col>
                <a-col :span="11">
                    <div class="label">B:</div> <a-input-number style="width:170px;" v-model="numberB" :disabled="otherSelected!=='bt'&&otherSelected!=='nbt'" />
                </a-col>
            </a-row>
        </div>

        <div class="btns">
            <a-button type="primary" ghost @click="cancle">取消</a-button>
            <a-button type="primary" @click="goApply">应用</a-button>
        </div>
    </a-modal>
</template>

<script>
import { messageTips } from '../../../until/tools';
export default {
    name:'Filters',
    props:['visible','s1'],
    data() {
        return {
            filterVisible:false,
            conditions:[
                {
                    name:'0',
                    title:'隐藏0单元格'
                },
                {
                    name:'empty',
                    title:'隐藏空单元格'
                }
            ],
            otherConditions : [
                {value:'0',name:'无'},
                {value:'eq',name:'等于A'},
                {value:'neq',name:'不等于A'},
                {value:'gt',name:'大于A'},
                {value:'lt',name:'小于A'},
                {value:'gte',name:'大于或等于A'},
                {value:'lte',name:'小于或等于A'},
                {value:'bt',name:'A,B之间'},
                {value:'nbt',name:'小于A或者大于B'}
            ],
            otherSelected:'0',
            selectedConditions:['empty'],
            numberA:null,
            numberB:null
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.filterVisible = true;
            } else {
                this.filterVisible = false;
            }
        },
        s1(value) {
            let arr = value.dealStr.split(',');
            let index = arr.indexOf('empty');
            if(index!==-1) {
                if(this.selectedConditions.indexOf('empty')===-1) {
                    this.selectedConditions.push('empty');
                }
                arr.splice(index,1);
            }
            let index2 = arr.indexOf('0');
            if(index2!==-1) {
                if(this.selectedConditions.indexOf('0')===-1) {
                    this.selectedConditions.push('0');
                }
                arr.splice(index2,1);
            }
            if(arr[0]) {
                let arr2 = arr[0].split('_');
                if(arr2[0]==='between') {
                    this.otherSelected = 'bt';
                } else if(arr2[0]==='out') {
                    this.otherSelected = 'nbt';
                } else {
                    this.otherSelected = arr2[0];
                }
                
                if(arr2[1]) {
                    let arr3 = arr2[1].split('|');
                    this.numberA = parseInt(arr3[0]);
                    if(arr3[1]) {
                        this.numberB = parseInt(arr3[1]);
                    }
                }
            }
        }
    },
    methods:{
        cancle() {
            this.filterVisible = false;
        },
        goApply() {
            var isCanGo = this.canGo();
            if(!isCanGo.can) {
                messageTips(isCanGo.msg,2);
                return false;
            }
            var dealStr = this.selectedConditions.join(',');
            if(this.otherSelected!=='0') {
                var str ='';
                switch(this.otherSelected) {
                    case 'bt':
                        str = 'between_'+this.numberA+'|'+this.numberB;
                    break;
                    case 'nbt':
                        str = 'out_'+this.numberA+'|'+this.numberB
                    break;
                    default:
                        str = this.otherSelected+'_'+this.numberA;
                    break;
                }
                if(dealStr) {
                    dealStr+=','+str;
                } else {
                    dealStr = str;
                }
            } else {
                var arr = [];
                if(this.selectedConditions.indexOf('0')!=-1) {
                    arr.push('0');
                }
                if(this.selectedConditions.indexOf('empty')!=-1) {
                    arr.push('empty');
                }
                dealStr = arr.join(',');
            }
            this.$emit('filterApply',{name:'filterApply',s1:{dealStr:dealStr}});
            this.filterVisible = false;
        },
        
        canGo() {
            let result;
            switch(this.otherSelected) {
                case '0':
                    result = {can:true};
                break;
                case 'bt':
                case 'nbt':
                    if(this.numberA>this.numberB||this.numberA===this.numberB) {
                        result = {can:false,msg:'数据A必须小于数据B'};
                    } else {
                        result = {can:true};
                    }
                break;
                default:
                    if(this.numberA===null||this.numberA===undefined||this.numberA===false) {
                        result = {can:false,msg:'数据A不能为空'};
                    } else {
                        result = {can:true};
                    }
                break;
            }
            return result;
        }
    }
}
</script>

<style lang="scss" scoped>
.condition {
    .h2 {
        font-weight: bold;
        line-height: 30px;
    }
    .label {
        display: inline-block;
        line-height: 32px;
        width:30px;
    }
}
</style>

