<template>
    <a-modal v-model="filterVisible" title="指标信息" :footer="null" :centered="true">
        <div v-if="infomation!==null">
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="60px">名称</a-col>
                <a-col flex="auto">{{infomation.dimFName}}</a-col>
            </a-row>
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="60px">来源</a-col>
                <a-col flex="auto">{{infomation.dimSource}}</a-col>
            </a-row>
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="60px">机构</a-col>
                <a-col flex="auto">{{infomation.org}}</a-col>
            </a-row>
            <a-row type="flex" :gutter="[16,16]">
                <a-col flex="60px">说明</a-col>
                <a-col flex="auto">{{infomation.dimComment}}</a-col>
            </a-row>
        </div>
    </a-modal>
</template>

<script>
export default {
    name:'IndicatorInfo',
    props:['visible','params'],
    data() {
        return {
            filterVisible:false,
            infomation:null
        }
    },
    watch:{
        visible(value) {
            if(value) {                
                this.filterVisible = true;
                this.infomation = null;
                let params = {
                    dimCode:this.params.obj,
                    cubeId:this.params.cubeId,
                    dimType:'INDICATOR'
                }
                this.$http.getDimInfo(params).then(res=>{
                    if(res&&res.information) {
                        this.infomation = res.information;
                    }
                });
            } else {
                this.filterVisible = false;
            }
        }
    },
}
</script>