<template>
    <a-modal v-model="filterVisible" title="80/20分析" :footer="null" :centered="true">
        <div class="tips">
            帕累托分析显示选中一组数值中较大的数值, 最小一部分数值仅显示合计值（这部分数值的合计正好占这组数值的总和的20%）。
        </div>
        <div class="select-col">
            <a-row type="flex">
                <a-col flex="80px">
                    <div class="label">分析列：</div>
                </a-col>
                <a-col flex="auto">
                    <a-select default-value="无" v-model="selectedCol" style="width: 100%">
                        <a-select-option v-for="item in list" :key="item.index">
                            {{item.key}}
                        </a-select-option>
                    </a-select>
                </a-col>
            </a-row>
        </div>
        <div class="set-val">
            <a-checkbox :checked="needSetPercent" @change="e=>{needSetPercent=e.target.checked}">自定义比例设置top N%</a-checkbox>
            <a-input-number style="width:290px" :max="100" :disabled="!needSetPercent" v-model="percent" placeholder="N为1~100以内的数字" />
        </div>
        <div class="condition">
            <div class="h2">样式</div>
            <div class="styles">
                <a-row :gutter="[16,16]">
                    <a-col :span="8">
                        <div class="label">单元格背景：</div>
                        <div class="color-select">
                            <colorPicker v-model="selectedBgColor" />
                        </div>
                    </a-col>
                    <a-col :span="8">                    
                        <div class="label">字体颜色：</div>
                        <div class="color-select">
                            <colorPicker v-model="selectedColor" />
                        </div>
                    </a-col>
                    <a-col :span="8">                    
                        <div class="label" style="width:80px">字体大小：</div>
                        <a-input-number style="width:60px" v-model="selectedFontSize" size="small" />
                    </a-col>
                </a-row>

                <a-row :gutter="[16,16]">
                    <a-col :span="15">
                        <a-checkbox-group v-model="selectedStyles">
                            <a-checkbox  v-for="item in fontStyles" :key="item.id" :style="item.style" :value="item.id">{{item.name}}</a-checkbox>
                        </a-checkbox-group>
                    </a-col>
                </a-row>
            </div>
        </div>

        <div class="btns">
            <a-button type="primary" ghost @click="cancle">取消</a-button>
            <a-button type="primary" @click="goApply">应用</a-button>
        </div>
    </a-modal>
</template>

<script>
import { messageTips } from '../../../until/tools';
export default {
    name:'HightLight',
    props:['visible','sheetId'],
    data() {
        return {
            filterVisible:false,
            list:[],
            selectedCol:'无',
            selectedBgColor:'#92D050',
            selectedColor:'#282828',
            selectedStyles:[],
            percent:null,
            needSetPercent:false,
            selectedFontSize:12,
            fontStyles:[
                {id:'bolder',name:'加粗',style:{fontWeight:'bold'}},
                {id:'italic',name:'斜体',style:{fontStyle:'italic'}},
                {id:'underline',name:'下划线',style:{textDecoration:'underline'}}
            ]
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.filterVisible = true;
                
                this.$http.getColumnsMsg({sheetId:this.sheetId}).then(res=>{
                    if(res) {
                        this.list = res;
                    }
                });
            } else {
                this.filterVisible = false;
            }
        }
    },
    methods:{
        cancle() {
            this.filterVisible = false;
        },
        goApply() {
            let fontStyles = {
                color:this.selectedColor,
                fontStyles:this.selectedStyles,
                fontSize:this.selectedFontSize+'px'
            }
            let stringStyles = JSON.stringify(fontStyles);
            var s3 = {
                index8020:this.selectedCol,
                bgColor8020:this.selectedBgColor,
                fontStyle8020:stringStyles
            };
            if(this.selectedCol==='无') {
                messageTips('请选择序列',2);
                return false;
            }
            if(this.needSetPercent&&!this.percent) {
                messageTips('请设置自定义比例',2);
                return false;
            } else {
                s3.percent8020 = this.percent/100;
            }
            this.$emit('analysisET',{name:'analysisET',s3});
            this.filterVisible = false;
        },
           
    }
}
</script>

<style lang="scss" scoped>
.select-col {
    margin:20px 0;
    .label {
        line-height: 32px;
        font-weight: bold;
    }
}
.set-val {
    margin:20px 0;
}
.condition {
    .h2 {
        font-weight: bold;
        line-height: 30px;
    }
    .label {
        display: inline-block;
        line-height: 32px;
        width:30px;
    }
    .styles {
        .label {
            display: block;
            line-height: 24px;
            width:84px;
            float:left;
            text-align: right;
        }
        .color-select {
            width:24px;
            height:24px;
            border:1px solid #ddd;
            display: inline-block;
            padding:1px;            
        }
        
    }
    
}
</style>