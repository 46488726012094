<template>
    <a-modal v-model="filterVisible" title="条件样式" :footer="null" :centered="true">
        
        <a-row>
            <a-col :span="12">
                <div class="h2">色调</div>
                <div class="tones">
                    <div class="item" v-for="(item,key) in tones" :key="item.name" :title="item.title" :class="{'active':selectedTone===(key+1)}" @click="selectTone(key)">
                        <i class="ico-d" :class="'ico-'+item.name"></i>
                    </div>
                </div>
            </a-col>
            <a-col :span="12">
                <div class="h2 hx">图标</div>
                <div class="icons">
                    <div class="item" v-for="item in icons" :key="item.id" :title="item.title" :class="{'active':selectedIcon===item.id}" @click="selectIcon(item.id)">
                        <i class="ico-d" :class="item.name"></i>
                    </div>
                </div>
            </a-col>
        </a-row>

        <div class="btns">
            <a-button type="primary" ghost @click="cancle">取消</a-button>
            <a-button type="primary" @click="goApply">应用</a-button>
        </div>
    </a-modal>
</template>

<script>
import { messageTips } from '../../../until/tools';
export default {
    name:'ConditionStyle',
    props:['visible','selectedParams'],
    data() {
        return {
            filterVisible:false,
            tones:[
                {name:'greenRed',title:'绿=黄-红色阶'},
                {name:'redGreen',title:'红-黄-绿色阶'},
                {name:'yellowOrange',title:'黄-红色阶'},
                {name:'orangeYellow',title:'红-黄色阶'},
                {name:'purpleRed',title:'蓝-黄-红色阶'},
                {name:'redPurple',title:'红-黄-蓝色阶'},
                {name:'greenYellow',title:'绿-黄色阶'},
                {name:'yellowGreen',title:'黄-绿色阶'}
            ],
            icons : [
                {name:'icone',title:'等级（彩色）',id:5},
                {name:'iconf',title:'等级（灰色）',id:6},
                {name:'iconj',title:'环状填充（灰色）',id:10},
                {name:'iconl',title:'柱状',id:12}
            ],
            selectedTone:null,
            selectedIcon:null,
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.filterVisible = true;
            } else {
                this.filterVisible = false;
            }
        },
        selectedParams(value) {
            if(value) {
                if(value.iconType) {
                    this.selectedIcon = value.iconType;
                }
                if(this.toneType) {
                    this.selectedTone = value.toneType;
                }
            } else {
                this.selectedIcon = null;
                this.selectedTone = null;
            }
        }
    },
    methods:{
        cancle() {
            this.filterVisible = false;
        },
        selectTone(key) {
            if(this.selectedTone===key+1) {
                this.selectedTone = null;
            } else {
                this.selectedTone = key+1;
            }
        },
        selectIcon(id) {
            if(this.selectedIcon===id) {
                this.selectedIcon = null;
            } else {
                this.selectedIcon = id;
            }
        },
        goApply() {
            if(!this.selectedTone&&!this.selectedIcon) {
                messageTips('请选择色调或图标',2);
                return false;
            }
            let params = {toneType:'',iconType:''};
            if(this.selectedTone) {
                params.toneType = this.selectedTone
            }
            if(this.selectedIcon) {
                params.iconType = this.selectedIcon;
            }
            this.$emit('conditionStyle',{name:'conditionStyle',data:params});
            this.filterVisible = false;
        },
        
        
    }
}
</script>

<style lang="scss" scoped>
.h2 {
    padding-left:67px;
    font-weight: bold;
    padding-bottom: 10px;
}
.hx {
    padding-left: 50px;
}
.ico-d {
    background-image: url(~@/assets/datas-icon.png);
}
.tones {
    padding-left:60px;
    .item {
        width: 44px;
        height: 39px;
        float: left;
        margin: 0 16px 15px 0;
        cursor: pointer;
        .ico-d {
            display: block;
            width: 29px;
            height: 25px;
            margin: 7px auto;
        }
        .ico-greenRed {
            background-position: 0 0;
        }
        .ico-redGreen {
            background-position: 0 -26px;
        }
        .ico-yellowOrange {
            background-position: 0 -52px;
        }
        .ico-orangeYellow {
            background-position: 0 -78px;
        }
        .ico-purpleRed {
            background-position: 0 -104px;
        }
        .ico-redPurple {
            background-position: 0 -130px;
        }
        .ico-greenYellow {
            background-position: 0 -156px;
        }
        .ico-yellowGreen {
            background-position: 0 -182px;
        }
    }
    .active,.item:hover {
        background:#f0f3f6;
    }
}
.icons {
    padding-left:40px;
    .item {
        width: 88px;
        margin: 10px 5px 30px;
        cursor: pointer;
        height: 20px;
        padding:3px 5px
    }
    .active,.item:hover {
        background:#f0f3f6;
    }
    .ico-d {
        height:15px;
        display: block;
    }
    .icone {
        background-position: -30px 0;
    }
    .iconf {
        background-position: -30px -15px;
    }
    .iconj {
        background-position: -30px -30px;
    }
    .iconl {
        background-position: -30px -47px;
    }
}
</style>