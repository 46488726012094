<template>
    <a-spin style="height:100%" :spinning="loading" tip="正在查询，请稍候……">
        <a-layout class="search-page-index">
            <a-layout-sider :style="pageLayoutSiderStyle">
                <div class="search-sider-inner" style="overflow:auto;">
                    <dl v-if="factMap.freqList">
                        <dt>频度筛选</dt>
                        <dd v-for="item in factMap.freqList" :key="item.freqId" :class="{'active':item.freqId===freqId}" @click="selectFreqId(item.freqId)">
                            <a-icon type="close" v-if="item.freqId===freqId" @click.stop="selectFreqId(null)" />
                            <div class="num">{{item.indicatorNum}}</div>
                            {{item.name}}
                        </dd>
                    </dl>

                    <dl v-if="factMap.newDataYearList">
                        <dt>最新数据时间</dt>
                        <dd v-for="item in factMap.newDataYearList" :key="item.newDataYear" :class="{'active':item.newDataYear===year}" @click="selectYear(item.newDataYear)">
                            <a-icon type="close" v-if="item.newDataYear===year" @click.stop="selectYear(null)" />
                            <div class="num">{{item.indicatorNum}}</div>
                            {{item.name}}
                        </dd>
                    </dl>
                    <!-- <dl v-if="factMap.regclcList">
                        <dt>区域筛选</dt>
                        <dd v-for="item in factMap.regclcList" :key="item.regclc" :class="{'active':item.regclc===regclc}" @click="selectRegclc(item.regclc)">
                            <a-icon type="close" v-if="item.regclc===regclc" @click.stop="selectRegclc(null)" />
                            <div class="num">{{item.indicatorNum}}</div>
                            {{item.name}}
                        </dd>
                    </dl>

                    <dl v-if="factMap.sourceList">
                        <dt>来源筛选</dt>
                        <dd v-for="(item,key) in factMap.sourceList" :key="key" :class="{'active':item.source===source}" @click="selectSource(item.source)">
                            <a-icon type="close" v-if="item.source===source" @click.stop="selectSource(null)" />
                            <div class="num">{{item.indicatorNum}}</div>
                            {{item.sourceZh}}
                        </dd>
                    </dl> -->
                    <dl v-if="factMap.IndustryList">
                        <dt>按国民经济行业分类</dt>
                        <dd v-for="(item,key) in factMap.IndustryList" :key="key" :class="{'active':item.isIndustry===isIndustry}" @click="selectIndustry(item.isIndustry)">
                            <a-icon type="close" v-if="item.isIndustry===isIndustry" @click.stop="selectIndustry(null)" />
                            <div class="num">{{item.indicatorNum}}</div>
                            {{item.isIndustry==='1'?'是':'否'}}
                        </dd>
                    </dl>

                    
                    
                </div>
            </a-layout-sider>
            <a-layout-content style="height:100%">
                <a-layout style="height:100%">
                    <a-layout-header class="fixedtop" style="height:50px;line-height:50px;background:#fff;padding:0 10px;">
                        <a-button type="primary" @click="getDims">显示数据</a-button>
                        已选{{selectedData.indicators.length}}条指标 <span class="iconfont icon-shanchu" title="清空选择" @click="clearSelect"></span> <span class="red">*注意请选择同一个数据库条目来显示数据</span>
                    </a-layout-header>
                    <a-layout-content style="height:100%;overflow:auto;">
                        <div class="ant-table ant-table-scroll-position-left ant-table-middle" style="height:calc(100% - 100px);overflow:auto;">
                            <div class="ant-table-content">
                                <div class="ant-table-body">
                                    <table>
                                        <thead class="ant-table-thead">
                                            <tr>
                                                <th></th>
                                                <th><span class="ant-table-header-column">指标名称</span></th>
                                                <th><span class="ant-table-header-column">区域</span></th>
                                                <th><span class="ant-table-header-column">频度</span></th>
                                                <th><span class="ant-table-header-column">起止时间</span></th>
                                            </tr>
                                        </thead>
                                        <tbody class="ant-table-tbody" v-for="(item,key) in entityList" :key="key">
                                            <tr class="ant-table-row ant-table-row-level-0 cube">
                                                <td><a-checkbox :checked="isChecked(item)" :indeterminate="indeterminate(item)" @click="selectCube(item)" /></td>
                                                <td colspan="5">{{item.cube.cubeNameZh}}</td>
                                            </tr>
                                            <tr class="ant-table-row ant-table-row-level-0" v-for="(i,k) in item.indicatorList" :key="key+'-'+k">
                                                <td><a-checkbox :checked="selectedData.indicators&&selectedData.indicators.indexOf(i.indicatorCode)!==-1&&selectedData.cubeId===parseInt(i.cubeId)" @click="selectIndicator(item.cube.cubeId,i)" /></td>
                                                <td v-html="i.nameZh"></td>
                                                <td>{{i.regclc}}</td>
                                                <td>
                                                    <span v-if="i.freqId==='1'">年度</span>
                                                    <span v-if="i.freqId==='3'">季度</span>
                                                    <span v-if="i.freqId==='4'">月度</span>
                                                </td>
                                                <td>{{i.startDate}}~{{i.lastDate}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        
                        <div class="search-page" v-if="page">
                            <a-pagination size="small" @change="search" :current="page.currentPage" :pageSize="page.pageSize" :total="page.allSize"  :show-total="total => `共 ${page.allSize} 个子库 | 每页 ${page.pageSize} 个子库`" show-quick-jumper />
                        </div>
                    </a-layout-content>
                </a-layout>
            </a-layout-content>
        </a-layout>
    </a-spin>
</template>

<script>
import {pageLayoutSiderStyle} from '../../until/global_style';
import { delSessionItem, getSessionItem, messageTips, setSessionItem } from '../../until/tools';

export default {
    data() {
        return {
            pageLayoutSiderStyle:pageLayoutSiderStyle,
            entityList:[],
            factMap:{},
            page:null,
            freqId:null,
            year:null,
            regclc:null,
            source:null,
            isIndustry:null,
            loading:false,
            selectedData:{
                cubeId:null,
                indicators:[]
            }
        }
    },
    computed:{
        keywords(){
            return this.$route.query.keywords;
        },
    },
    watch:{
        keywords() {
            this.entityList = [];
            this.factMap = {};
            this.page = null;
            this.search(1);
        }
    },
    created() {
        this.search(1);
    },
    methods:{
        isChecked(item) {
            if(this.selectedData.cubeId===item.cube.cubeId) {
                if(this.selectedData.indicators&&this.selectedData.indicators.length===item.indicatorList.length&&this.selectedData.indicators.length>0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        indeterminate(item) {
            if(this.selectedData.cubeId===item.cube.cubeId) {
                if(this.selectedData.indicators&&this.selectedData.indicators.length!==item.indicatorList.length&&this.selectedData.indicators.length>0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        selectCube(item) {
            if(this.selectedData.cubeId&&this.selectedData.cubeId!==item.cube.cubeId) {
                messageTips('选择同一个数据库条目来显示数据',2);
                return false;
            }
            if(this.isChecked(item)) {
                this.selectedData = {
                    cubeId:null,
                    indicators:[]
                }
            } else {
                this.selectedData = {
                    cubeId:item.cube.cubeId,
                    indicators:[]
                }
                item.indicatorList.forEach(i=>{
                    this.selectedData.indicators.push(i.indicatorCode);
                });
            }            
        },
        selectIndicator(cubeId,item) {
            if(this.selectedData.cubeId) {
                if(this.selectedData.cubeId!==cubeId) {
                    messageTips('选择同一个数据库条目来显示数据',2);
                    return false;
                }
            }
            this.selectedData.cubeId = cubeId;
            
            if(this.selectedData.indicators) {
                let index = this.selectedData.indicators.indexOf(item.indicatorCode);
                if(index===-1) {
                    this.selectedData.indicators.push(item.indicatorCode)
                } else {
                    this.selectedData.indicators.splice(index,1);
                    if(this.selectedData.indicators.length===0) {
                        this.selectedData.cubeId = null;
                    }
                }
            }
        },
        search(page) {
            let params = {
                keywords:this.keywords,
                url:'stream',
                cubeScope:0,
                page
            }
            if(this.freqId) {
                params['freqId'] = this.freqId;
            }
            if(this.year) {
                params['year'] = this.year;
            }
            if(this.regclc) {
                params['regclc'] = this.regclc;
            }
            if(this.source) {
                params['source'] = this.source;
            }
            if(this.isIndustry) {
                params['isIndustry'] = this.isIndustry;
            }
            this.clearSelect();
            this.loading = true;
            this.$http.searchPage(params).then(res=>{
                this.loading = false;
                if(res) {
                    if(res.entity) {
                        res.entity.factMap.newDataYearList.forEach((v)=> {
                            let arr = v.newDataYear.split('_');
                            let name 
                            if(arr[2]!=3) {
                                name = parseInt(arr[0])-parseInt(arr[1])+'年';
                            } else {
                                name = parseInt(arr[0])-parseInt(arr[1])+'年以前'
                            }
                            v.name = name;
                        });
                        this.factMap = res.entity.factMap;
                        this.entityList = res.entity.entityList;
                        this.page = res.entity.page;
                    }
                }
                
            })
        },
        selectFreqId(data) {
            this.freqId = data;
            this.search(1);
        },
        selectYear(data) {
            this.year = data;
            this.search(1);
        },
        selectSource(data) {
            this.source = data;
            this.search(1);
        },
        selectRegclc(data) {
            this.regclc = data;
            this.search(1);
        },
        selectIndustry(data) {
            this.isIndustry = data;
            this.search(1);
        },
        clearSelect() {
            this.selectedData = {
                cubeId:null,
                indicators:[]
            }
        },
        getDims() {
            if(!this.selectedData.cubeId) {
                messageTips('请选择数据',2);
                return false;
            }
            if(getSessionItem('dims')) {
                delSessionItem('dims');
            }            
            let dims = {
                controls:{
                    s1:{dealStr:'empty'},
                    s2:{method:'',type:''},
                    s3:{index8020:'',fontStyle8020:'',bgColor8020:''},
                    s4:{orderStr:'',index:''},
                    s5:{dealStr:'',showBackColor:'',showFontStyle:''},
                },
                metaRow:['TIME'],
                metaFixed:[],
                metaColumn:[]
            }
            this.$http.searchDimensionsMode({cubeId:this.selectedData.cubeId,indicatorCode:this.selectedData.indicators.join(',')}).then(res=>{
                if(res) {
                    if(res.dimensionMode) {
                        let dimensionVOLst = [];
                        for(let i in res.dimensionMode) {
                            let obj;
                            if(i==='INDICATOR') {
                                obj = {codeName:i,codes:this.selectedData.indicators}
                            } else {
                                obj = {codeName:i,codes:res.dimensionMode[i].split(',')}
                            }                            
                            dimensionVOLst.push(obj);
                            if(i!=='TIME') {
                                dims.metaColumn.push(i);
                            }
                        }
                        dims.dimensionVOLst = dimensionVOLst;
                        dims.cubeId = this.selectedData.cubeId;
                        setSessionItem('dims',dims);
                        this.$router.push({path:'/datas_data',query:{cubeId:this.selectedData.cubeId}});
                    } else {
                        messageTips(res.message,1);
                    }
                }
            })
        }
    }
}
</script>

<style lang="scss">
@import '../../scss/datas/search.scss'
</style>