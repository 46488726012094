<template>
    <a-modal v-model="filterVisible" title="添加序列" :footer="null" :width="300" :centered="true">
        <a-spin :spinning="spinning" tip="添加中……">
            <div class="option">
                <div class="h2">添加到已有序列集</div>
                <div class="list">
                    <vue-scroll :ops="ops" style="height:100%">
                    <div class="item" v-for="item in list" :key="item.cube_ID" @click="goApply(item.cube_ID)">
                        <span class="iconfont icon-shujuji"></span>{{item.cube_NAME}}
                    </div>
                    </vue-scroll>
                </div>
            </div>
            
            <div class="new">
                <div v-if="!showNew" @click="showNew=true">
                    <a-icon type="plus" />
                    添加新序列集
                </div>
                <div v-else>
                    <a-icon class="f-l" type="plus" />
                    <a-input v-model="fileName" placeholder="输入序列集名称" size="small" />
                    <div class="ctrl-btn">
                        <a-button type="primary" ghost @click="showNew=false" size="small">取消</a-button>
                        <a-button type="primary" size="small" @click="addNew" :disabled="adding">确定</a-button>
                    </div>
                </div>
            </div>
        </a-spin>
    </a-modal>
</template>

<script>
import { messageTips } from '../../../until/tools';
import config from '../../../until/configs';
export default {
    name:'Collections',   
    props:['visible','sheetId','cubeId'],
    data() {
        return {
            filterVisible:false,         
            fileName:'',
            list:[],
            showNew:false,
            ops: config.vueScrollSetting,
            reportId:0,
            adding:false,
            spinning:false
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.filterVisible = true;
                this.fileName = '';
                this.showNew = false;
                this.list = [];
                this.reportId = 0;
                this.$http.getSeriesList({cubeId:this.cubeId}).then(res=>{
                    if(res) {
                        this.list = res;
                    }
                });
            } else {
                this.filterVisible = false;
            }
        }
    },
    methods:{
        cancle() {
            this.filterVisible = false;
        },
        addNew() {
            this.adding = true;
            this.$http.createSeries({cubeName:this.fileName}).then(res=>{
                if(res) {    
                    this.adding = false;                    
                    if(res.reportId) {
                        this.goApply(res.reportId);
                        this.$http.getSeriesList({cubeId:this.cubeId}).then(res=>{
                            if(res) {
                                this.list = res;
                            }
                        });
                    } else {
                        messageTips(res.message,1)
                    }                    
                }
            });
        },
        goApply(reportId) {
            let params = {reportId,sheetId:this.sheetId};
            this.spinning = true;
            this.$http.addSeries(params).then(res=>{
                this.spinning = false;
                if(res) {
                    if(res.success) {
                        messageTips('添加成功，请到云分析平台或个人中心中查看',3);
                        this.filterVisible = false;
                    } else {                        
                        if(res.type===4) {
                            var that = this;
                            this.$confirm({
                                title: '提示',
                                content: res.message,
                                okText: '确定',
                                cancelText: '取消',
                                centered:true,
                                onOk() {
                                    that.$http.updateReportForm({sheetId:that.sheetId}).then(res=>{
                                        if(res.success) {
                                            messageTips('添加成功，请到云分析平台或个人中心中查看',3);
                                            that.filterVisible = false;
                                        }
                                    })
                                },
                                onCancel() {}
                            });
                        } else {
                            messageTips(res.message,1);
                        }
                    }
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/_variables.scss';
.h2 {
    padding-bottom: 5px;
    font-weight: bold;
}
.option {
    margin-bottom: 20px;
    .list {
        height:200px;
        .item {
            cursor: pointer;
            padding:3px 5px;
        }
        .item:hover {
            background: #f0f3f6;
        }
    }
}
.new {
    border-top:1px solid #ddd;
    margin-bottom:20px;
    padding-top:10px;
    cursor: pointer;
    .f-l {
        float:left;
        margin:4px 0;
    }
    .ant-input {
        margin-left:15px;
        width:220px;
    }
    .ctrl-btn {
        clear:both;
        text-align: center;
        padding-top:20px;
        button {
            margin:0 20px;
        }
        .ant-btn-primary {
            background-color: $primary-color;
            border-color: $primary-color;
        }
        .ant-btn-background-ghost.ant-btn-primary {
            color:$primary-color;
            border-color: $primary-color;
        }
    }
}
</style>