<template>
    <a-modal v-model="filterVisible" title="合并计算" :footer="null" :width="600" :centered="true">
        
        <a-row>
            <a-col :span="12">
                <div class="h2">按行</div>
                <div class="tones">
                    <a-checkbox-group v-model="selectedRows">
                        <a-row>
                            <a-col v-for="(item,key) in rowWays" :key="key" :span="12">
                                <div class="check-item" v-for="i in item" :key="i.key">
                                    <a-checkbox :value="i.key">{{i.text}}</a-checkbox>
                                </div>
                            </a-col>
                        </a-row>
                    </a-checkbox-group>                    
                </div>
            </a-col>
            <a-col :span="12">
                <div class="h2">按列</div>
                <div class="tones">
                    <a-checkbox-group v-model="selectedCols">
                        <a-row>
                            <a-col v-for="(item,key) in colWays" :key="key" :span="12">
                                <div class="check-item" v-for="i in item" :key="i.key">
                                    <a-checkbox :value="i.key">{{i.text}}</a-checkbox>
                                </div>
                            </a-col>
                        </a-row>
                    </a-checkbox-group>  
                </div>
            </a-col>
        </a-row>

        <div class="btns">
            <a-button type="primary" ghost @click="cancle">取消</a-button>
            <a-button type="primary" @click="goApply">应用</a-button>
        </div>
    </a-modal>
</template>

<script>
import { messageTips } from '../../../until/tools';
const ways = [
    [
        {'key':'sum', 'text': '求和'},
        {'key':'mean', 'text': '均值'},
        {'key':'max', 'text': '最大值'},
        {'key':'min', 'text': '最小值'},
        {'key':'mode', 'text': '众数'},
        {'key':'median', 'text': '中位数'},
        {'key':'variance', 'text': '方差'},
        {'key':'sd', 'text': '标准差'}
    ],
    [
        {'key':'skewness', 'text': '偏度'},
        {'key':'kurtosis', 'text': '峰度'},
        {'key':'range', 'text': '极差'},
        {'key':'squares', 'text': '平方和'},
        {'key':'upperdecile', 'text': '下十分位'},
        {'key':'lowerdecile', 'text': '上十分位'},
        {'key':'upperquartile', 'text': '下四分位'},
        {'key':'lowerquartile', 'text': '上四分位'}
    ]
];
export default {
    name:'TableCalculate',
    props:['visible','s2'],
    data() {
        return {
            filterVisible:false,
            rowWays:[...ways],
            colWays:[...ways],
            selectedRows:[],
            selectedCols:[]
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.filterVisible = true;
            } else {
                this.filterVisible = false;
            }
        },
        s2(value) {
            if(value) {
                if(value.type) {
                    let arr1 = value.type.split('_');
                    let arr2 = value.method.split(',');
                    if(arr1>1) {
                        this.selectedCols = arr2[0].split('_');
                        this.selectedRows = arr2[1].split('_');
                    } else {
                        if(arr1[0]==='rows') {
                            if(arr2[0]) {
                                this.selectedCols = arr2[0].split('_');
                            }
                        } else {
                            if(arr2[0]) {
                                this.selectedRows = arr2[0].split('_');
                            }
                        }
                    }
                } else {
                    this.selectedRows = [];
                    this.selectedCols = [];
                }                
            }
        }
    },
    methods:{
        cancle() {
            this.filterVisible = false;
        },
        
        goApply() {
            if(this.selectedCols.length==0&&this.selectedRows.length==0) {
				messageTips('请选择方法',2);
				return false;
			}

            let type = null;
			let method = null;
			if(this.selectedCols.length==0&&this.selectedRows.length!=0) {
				type = 'columns'
				method = this.selectedRows.join('_');
			} else if(this.selectedCols.length!=0&&this.selectedRows.length==0) {
				type = 'rows'
				method = this.selectedCols.join('_');
			} else if(this.selectedCols.length!=0&&this.selectedRows.length!=0) {
				type = 'rows_columns';
				method = this.selectedCols.join('_') +','+ this.selectedRows.join('_');
            }
            let s2 = {type:type,method:method}
            this.$emit('tableCalculate',{name:'tableCalculate',s2});
            this.filterVisible = false;
        },
        
        
    }
}
</script>

<style lang="scss" scoped>
.h2 {
    font-weight: bold;
    padding-bottom: 10px;
}
.ico-d {
    background-image: url(~@/assets/datas-icon.png);
}
.tones {
    padding-left:0;
    .check-item {
        width:130px;
        padding:5px 0;
    }
}

</style>