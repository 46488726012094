<template>
<a-spin tip="正在加载，请稍候……" :spinning="spinning">
    <a-layout style="overflow:hidden;height:100%;">
        <a-layout-sider :style="pageLayoutSiderStyle">
            <div class="sider-inner" :style="{width:showSide?'100%':'10px'}">
                <!-- 收缩和展开 -->
                <div class="switch" @click="switchSider">
                    <a-icon type="backward" v-if="showSide" />
                    <a-icon type="forward" v-else />
                </div>
                <!-- 城市决算数据库大按钮 -->
                <div class="cubes" v-if="showSide">
                    <a-dropdown  :trigger="['click']" placement="bottomLeft">
                        <a-button type="primary" size="large" style="width:210px">{{ currentCubeObj.cubeNameZh }}<a-icon type="caret-down" v-if="currentCubeObj.children&&currentCubeObj.children.length>0" /></a-button>
                        <a-menu slot="overlay" v-if="currentCubeObj.children&&currentCubeObj.children.length>0">
                            <a-menu-item v-for="item in currentCubeObj.children" :key="item.cubeId">
                                <router-link :to="{path:'/datas_data',query:{pCubeId:currentCubeObj.cubeId,cubeId:item.cubeId}}">{{item.cubeNameZh}}</router-link>
                            </a-menu-item>
                        </a-menu>
                    </a-dropdown>
                </div>
                <!-- 维度列表区 -->
                <div class="list" v-show="showSide">
                    <Dimensions 
                    :dimData="dimData" 
                    :cubeId="cubeId" 
                    :sheetId="sheetId" 
                    :freqId="freqId" 
                    ref="dimensions" 
                    @selectDimCode="selectDimCode" 
                    @dimensionDragEnd="dimensionDragEnd" 
                    :tagListTree="tagListTree"
                    :selectedCodes="selectedCodes"
                    :isInput="false" />
                    <div class="sync-data-btn" @click="goSync">
                        <span class="iconfont icon-sousuo"></span>
                        查询
                    </div>
                </div>
            </div>
        </a-layout-sider>
        <a-layout-content>
            <div class="data-area">
                <div class="table-area" :style="tableAreaStyle">
                    <div class="toolbar">
                        <div class="ctrl-btn">
                            <div class="item" @click="rotate">
                                <span class="iconfont icon-biaogezhuanzhi-"></span>
                                <div class="name">表格转置</div>
                            </div>
                            <div class="item" @click="filterFn($event)" :class="{active:syncParams.s1.dealStr}">
                                <span class="iconfont icon-lieguolv"></span>
                                <div class="name">筛选</div> 
                            </div>
                            <div class="item" @click="highLightFn" :class="{active:syncParams.s5.dealStr}">
                                <span class="iconfont icon-gaoliang"></span>
                                <div class="name">高亮</div>
                            </div>
                            <div class="item" @click="conditionStyleFn">
                                <span class="iconfont icon-shezhitiaojian"></span>
                                <div class="name">条件样式</div>
                            </div>
                            <div class="item wide" @click="cellFormatFn">
                                <span class="iconfont icon-danyuangeyangshi"></span>
                                <div class="name">单元格格式</div>
                            </div>
                            <div class="item" @click="tableCalculateFn" :class="{active:syncParams.s2.method||syncParams.s2.type}">
                                <span class="iconfont icon-jisuan"></span>
                                <div class="name">合并计算</div>
                            </div>
                            <div class="item" @click="GrowthRateFn">
                                <span class="iconfont icon-zhexiantu1"></span>
                                <div class="name">增长率</div>
                            </div>
                            <div class="item wide" @click="userFunctionFn">
                                <span class="iconfont icon-hanshu"></span>
                                <div class="name">自定义函数</div>
                            </div>
                            <div class="item wide" @click="AnalysisEightTowFn" :class="{active:syncParams.s3.index8020||syncParams.s3.fontStyle8020||syncParams.s3.bgColor8020}">
                                <span class="iconfont icon-icon-test"></span>
                                <div class="name">80/20分析</div>
                            </div>
                            <div class="item" @click="clearResult">
                                <span class="iconfont icon-shanchu"></span>
                                <div class="name">清除结果</div>
                            </div>
                            <div class="item" @click="collectFn">
                                <span class="iconfont icon-htmal5icon24"></span>
                                <div class="name">收藏</div>
                            </div>
                            <div class="item" @click="addSeriesFn">
                                <span class="iconfont icon-tianjia"></span>
                                <div class="name">添加序列</div>
                            </div>
                            <div class="item" @click="goDownload">
                                <span class="iconfont icon-xiazai1"></span>
                                <div class="name">下载</div>
                            </div>
                        </div>
                        <div class="bar-cover" v-if="!hotRef"></div>
                    </div>

                    <div class="table data-table" style="height:calc(100% - 65px)">
                        <div class="fixedName" v-if="sheetInfo">{{sheetInfo.fixName}}</div>
                        <div style="widht:100%;height:100%" ref="handsontable" v-show="showHandsontableDom"></div>   
                        <div v-show="!showHandsontableDom">
                            <div class="cubeInfo" v-if="cubeInfo">
                                <dl>
                                    <dt>{{cubeInfo.cubeFnameZh}}</dt>
                                    <dd><div class="label">数据量：</div>{{cubeInfo.totalNum}}<div class="clear"></div></dd>
                                    <dd><div class="label">数据起止时间：</div>{{cubeInfo.startDate}}~{{cubeInfo.lastDate}}<div class="clear"></div></dd>
                                    <dd><div class="label">数据频度：</div><span v-if="cubeInfo.freqId===1">年度</span><span v-if="cubeInfo.freqId===3">季度</span><span v-if="cubeInfo.freqId===4">月度</span><div class="clear"></div></dd>
                                    <dd><div class="label">最新更新时间：</div>{{cubeInfo.lstmodDate}}<div class="clear"></div></dd>
                                    <dd><div v-html="cubeInfo.descZh"></div></dd>
                                </dl>
                            </div>
                        </div>
                        <div class="no-data-cause" v-if="showNoDataCause">
                            <a-alert
                            message="无相关数据"
                            :description="noDataCause"
                            type="error"
                            show-icon
                            />
                        </div>
                    </div>
                </div>
                <div class="chart-area" :style="chartAreaStyle">
                    <div class="toolbar">
                        <div class="ctrl-btn">
                            <div class="item" :class="{active:chartType==='line'}" @click="createChart('line')">
                                <span class="iconfont icon-zhexiantu"></span>
                                <div class="name">折线图</div>
                            </div>
                            <div class="item" :class="{active:chartType==='bar'}" @click="createChart('bar')">
                                <span class="iconfont icon-zhuzhuangtu"></span>
                                <div class="name">柱状图</div>
                            </div>
                            <div class="item" :class="{active:chartType==='rotateBar'}" @click="createChart('rotateBar')">
                                <span class="iconfont icon-zhuzhuangtu1"></span>
                                <div class="name">条形图</div>
                            </div>
                            <div class="item wide" :class="{active:chartType==='stackBar'}" @click="createChart('stackBar')">
                                <span class="iconfont icon-duijizhuzhuangtu"></span>
                                <div class="name">堆积柱状图</div>
                            </div>
                            <div class="item wide" :class="{active:chartType==='stackRotateBar'}" @click="createChart('stackRotateBar')">
                                <span class="iconfont icon-duijitiaoxingtu"></span>
                                <div class="name">堆积条形图</div>
                            </div>
                            <div class="item" :class="{active:chartType==='area'}" @click="createChart('area')">
                                <span class="iconfont icon-duijimianjitu"></span>
                                <div class="name">面积图</div>
                            </div>
                            <div class="item" :class="{active:chartType==='pie'}" @click="createChart('pie')">
                                <span class="iconfont icon-bingtu"></span>
                                <div class="name">饼图</div>
                            </div>
                            <div class="item" :class="{active:chartType==='ring'}" @click="createChart('ring')">
                                <span class="iconfont icon-huanxingtu"></span>
                                <div class="name">环形图</div>
                            </div>
                            <div class="item" :class="{active:chartType==='radar'}" @click="createChart('radar')">
                                <span class="iconfont icon-leidatu"></span>
                                <div class="name">雷达图</div>
                            </div>
                            <div class="item wide" :class="{active:chartType==='fillRadar'}" @click="createChart('fillRadar')">
                                <span class="iconfont icon-pingtaitubiao_leidatu"></span>
                                <div class="name">填充雷达图</div>
                            </div>
                            <div class="item" :class="{active:chartType==='scatter'}" @click="scatterFn">
                                <span class="iconfont icon-tubiaoleisandiantu"></span>
                                <div class="name">散点图</div>
                            </div>
                            <div class="item" :class="{'active':showChartDataLabel}"  @click="showChartDataLabelFn">
                                <span class="iconfont icon-icon-test1"></span>
                                <div class="name">数值标签</div>
                            </div>
                            <div class="item" :class="{'active':showChartLegend}" @click="showChartLegendFn">
                                <span class="iconfont icon-tuli"></span>
                                <div class="name">图例</div>
                            </div>
                            <div class="item wide" :class="{'active':chartType===null}" @click="userChartFn">
                                <span class="iconfont icon-zidingyi"></span>
                                <div class="name">自定义图表</div>
                            </div>
                            <div class="item wide" @click="makeMap">
                                <span class="iconfont icon-ditu"></span>
                                <div class="name">生成地图</div>
                            </div>
                            <div class="item" @click="downloadImg(1)">
                                <span class="iconfont icon-xiazai"></span>
                                <div class="name">下载</div>
                            </div>
                        </div>
                        <div class="size-btn">
                            <span class="iconfont icon-zuixiaohua" title="最小化" v-if="chartAreaSize!==2" @click="chartAreaSize=2"></span>
                            <span class="iconfont icon-double-square" title="还原" v-if="chartAreaSize!==1" @click="chartAreaSize=1"></span>
                            <span class="iconfont icon-zuidahua_huaban1" title="最大化" v-if="chartAreaSize!==3" @click="checkBigChart"></span>
                        </div>
                        <div class="bar-cover" v-if="!hotRef" v-show="chartAreaSize!==2"></div>
                    </div>
                    <div class="chart" ref="chart"></div>
                </div>
            </div>
            <div class="big-chart" v-show="bigChartVisible">
                <div class="toolbar">
                    <div class="ctrl-btn">
                        <div class="item" :class="{active:chartType==='line'}" @click="createBigChart('line')">
                            <span class="iconfont icon-zhexiantu"></span>
                            <div class="name">折线图</div>
                        </div>
                        <div class="item" :class="{active:chartType==='bar'}" @click="createBigChart('bar')">
                            <span class="iconfont icon-zhuzhuangtu"></span>
                            <div class="name">柱状图</div>
                        </div>
                        <div class="item" :class="{active:chartType==='rotateBar'}" @click="createBigChart('rotateBar')">
                            <span class="iconfont icon-zhuzhuangtu1"></span>
                            <div class="name">条形图</div>
                        </div>
                        <div class="item wide" :class="{active:chartType==='stackBar'}" @click="createBigChart('stackBar')">
                            <span class="iconfont icon-duijizhuzhuangtu"></span>
                            <div class="name">堆积柱状图</div>
                        </div>
                        <div class="item wide" :class="{active:chartType==='stackRotateBar'}" @click="createBigChart('stackRotateBar')">
                            <span class="iconfont icon-duijitiaoxingtu"></span>
                            <div class="name">堆积条形图</div>
                        </div>
                        <div class="item" :class="{active:chartType==='area'}" @click="createBigChart('area')">
                            <span class="iconfont icon-duijimianjitu"></span>
                            <div class="name">面积图</div>
                        </div>
                        <div class="item" :class="{active:chartType==='pie'}" @click="createBigChart('pie')">
                            <span class="iconfont icon-bingtu"></span>
                            <div class="name">饼图</div>
                        </div>
                        <div class="item" :class="{active:chartType==='ring'}" @click="createBigChart('ring')">
                            <span class="iconfont icon-huanxingtu"></span>
                            <div class="name">环形图</div>
                        </div>
                        <div class="item" :class="{active:chartType==='radar'}" @click="createBigChart('radar')">
                            <span class="iconfont icon-leidatu"></span>
                            <div class="name">雷达图</div>
                        </div>
                        <div class="item wide" :class="{active:chartType==='fillRadar'}" @click="createBigChart('fillRadar')">
                            <span class="iconfont icon-pingtaitubiao_leidatu"></span>
                            <div class="name">填充雷达图</div>
                        </div>
                        <div class="item" :class="{active:chartType==='scatter'}" @click="scatterFn">
                            <span class="iconfont icon-tubiaoleisandiantu"></span>
                            <div class="name">散点图</div>
                        </div>
                        <div class="item" :class="{'active':showChartDataLabel}"  @click="showChartDataLabelFn">
                            <span class="iconfont icon-icon-test1"></span>
                            <div class="name">数值标签</div>
                        </div>
                        <div class="item" :class="{'active':showChartLegend}" @click="showChartLegendFn">
                            <span class="iconfont icon-tuli"></span>
                            <div class="name">图例</div>
                        </div>
                        <div class="item wide" :class="{'active':chartType===null}" @click="userChartFn">
                            <span class="iconfont icon-zidingyi"></span>
                            <div class="name">自定义图表</div>
                        </div>
                        <div class="item wide" @click="makeMap">
                                <span class="iconfont icon-ditu"></span>
                                <div class="name">生成地图</div>
                            </div>
                        <div class="item" @click="downloadImg(2)">
                            <span class="iconfont icon-xiazai"></span>
                            <div class="name">下载</div>
                        </div>
                    </div>
                    <div class="size-btn">
                        <a-icon type="fullscreen-exit" @click="closeBigChart" />
                    </div>
                </div>
                <div class="chart-dims" v-show="chartType&&chartType!=='scatter'">
                    <a-select style="width:100%;height:32px;overflow:hidden" :mode="chartType!=='pie'&&chartType!=='ring'?'multiple':'default'" placeholder="请选择序列" v-model="selectedSeries" @change="changeSeries">
                        <a-select-option  v-for="(item) in series" :key="item.index" :value="item.index">{{item.key}}</a-select-option>
                    </a-select>
                </div>
                <div class="chart" ref="bigChart">

                </div>
                <div class="switch" v-show="['line','bar','area','stackBar'].indexOf(this.chartType)!==-1">
                    <a-icon type="play-circle" v-if="!chartPlay" @click="runPlayChart(chartPlay)" />
                    <a-icon type="pause-circle" v-else @click="runPlayChart(chartPlay)" />
                </div>
            </div>
        </a-layout-content>

        <Filters :visible="filterVisible" @filterApply="windowApply" :s1="syncParams.s1" />
        <high-light :visible="highLightVisible" @highLight="windowApply" :s5="syncParams.s5" />
        <condition-style :visible="conditionStyleVisible" @conditionStyle="setConditionStyle" :selectedParams="conditionParams" />
        <cell-format :visible="cellFormatVisible" :sheetId="sheetId" @cellFormat="applyCellFormat" />
        <table-calculate :visible="tableCalculateVisible" @tableCalculate="windowApply" :s2="syncParams.s2" />
        <growth-rate :visible="GrowthRateVisible" :sheetId="sheetId" @growthRate="setGrowthRate" />
        <user-function :visible="UserFunctionVisible" :sheetId="sheetId" @userFunction="setUserFunction" />
        <analysis-eight-tow :visible="AnalysisEightTowVisible" :sheetId="sheetId" @analysisET="windowApply" />
        <Collections :visible="collectVisible" :sheetId="sheetId" @goCollection="goCollection" />
        <add-series :visible="addSeriesVisible" :sheetId="sheetId" :cubeId="cubeId" />
        <scatter-set :visible="scatterSetVisible" :sheetId="sheetId" @scatterSetCtrl="scatterSetCtrl" />
        <indicator-info :visible="indicatorInfoVisible" :params="infoParams" />
        <user-chart :visible="userChartVisible" :sheetId="sheetId" @setUserChart="setUserChart" :tableData="userChartData" :source="1" />
        <Map :visible="mapVisible" :mapData="mapData" />
    </a-layout>
</a-spin>
</template>

<script>
import axios from 'axios';
import config from '../../until/configs';
import {pageLayoutSiderStyle,pageLayoutSiderHideStyle} from '../../until/global_style';
import Dimensions from '../../components/Dimensions.vue';
import Filters from './components/Filters.vue';
import HighLight from './components/HighLight.vue';
import ConditionStyle from './components/ConditionStyle.vue';
import CellFormat from './components/CellFormat.vue'
import TableCalculate from './components/TableCalculate.vue';
import GrowthRate from '../../components/GrowthRate.vue';
import UserFunction from '../../components/UserFunction.vue';
import AnalysisEightTow from './components/AnalysisEightTow.vue';
import Collections from './components/Collections.vue';
import AddSeries from './components/AddSeries.vue';
import ScatterSet from '../../components/ScatterSet.vue';
import IndicatorInfo from './components/IndicatorInfo.vue';
import UserChart from '../../components/UserChart.vue';
// import UserInfo from '../../components/UserInfo.vue';
import Map from './components/Map.vue';
import {getCookie, getSessionItem, messageTips, objToUrl,savePicture} from '../../until/tools';
import {createSettings, tableParse} from '../../until/handsontableSetting';
import 'handsontable/dist/handsontable.full.css';
import Handsontable from 'handsontable/dist/handsontable.full.js';
import * as echarts from 'echarts';
import html2canvas from 'html2canvas';
import {setCharts,showChartLegendFn,showChartDataLabelFn,setBigChart,playBigChart} from '../../until/chart.js';
var chartHandle;
var bigChartHandle;
function setSyncParams() {
    return {
        s1:{dealStr:'empty'},
        s2:{method:'',type:''},
        s3:{index8020:'',fontStyle8020:'',bgColor8020:''},
        s4:{orderStr:'',index:''},
        s5:{dealStr:'',showBackColor:'',showFontStyle:''},
    }
}
function sequence(a,b){
    if (a>b) {
        return 1;
    }else if(a<b){
        return -1
    }else{
        return 0;
    }
}
export default {
    name:'Articles',
    components:{Dimensions,Filters,HighLight,ConditionStyle,CellFormat,TableCalculate,GrowthRate,UserFunction,AnalysisEightTow,Collections,AddSeries,ScatterSet,IndicatorInfo, UserChart,Map},
    data() {
        return {
            pageLayoutSiderStyle:pageLayoutSiderStyle,
            showSide:true,
            hotRef:null,
            showHandsontableDom:false,
            dimData:null,
            sheetId:null,
            newSheetId:'1321321',
            allDimensions:[],            
            cubeInfo:null,
            sheetInfo:null,
            filterVisible:false,
            highLightVisible:false,
            conditionStyleVisible:false,
            cellFormatVisible:false,
            tableCalculateVisible:false,
            GrowthRateVisible:false,
            UserFunctionVisible:false,
            AnalysisEightTowVisible:false,
            collectVisible:false,
            addSeriesVisible:false,
            scatterSetVisible:false,
            indicatorInfoVisible:false,
            userChartVisible:false,
            bigChartVisible:false,
            mapVisible:false,
            syncParams:setSyncParams(),
            chartType:'bar',
            showChartLegend:true,
            showChartDataLabel:false,
            selectedTableArea:[],
            infoParams:{},
            spinning:false,
            chartAreaSize:1,//1正常，2最小
            userChartData:null,
            tableCells:[],
            chartSeries:[],
            series:[],
            cols:[],
            selectedSeries:[],
            mapData:{},
            userChartOption:null,
            scatterData:null,
            conditionParams:null,
            chartPlay:false,
            loginVisible:false,
            tableData:null,
            tagListTree:{},
            selectedCodes:{},
            noDataCause:<div>
                <p>数据平台通过多个维度组合来展示数据。若出现查无数据情况，可能存在以下原因：</p>
                <p>1.因经济社会发展引起某些指标从某年起不再统计或更名统计；</p>
                <p>2.因政策性原因导致某些指标在某年后不再对社会公布；</p>
                <p>3.所选维度组合（时间维度、地区维度、指标维度、其他维度）未发生数据统计。例如：某种商品在某一时间在某一地区</p>
                <p>4.维度间匹配不正确。例如在指标维度选择的是省级指标，而在地区维度中却选择了地级市导致错配。</p>
            </div>,
            showNoDataCause:false,
            commentCellsArr:[],
            freqId:1
        }
    },
    computed: {
        cubeId() {
            let id = this.$route.query.cubeId;
            if(id) {
                return parseInt(id);
            }
            return 0;
        },
        cubes() {
            let arr = this.$store.getters.getCubes;
            let result = [];
            arr.forEach(item=>{
                if(item.children.length>0) {
                    result.push(item);
                }
            });            
            return result;
        },
        currentCubeObj() {
            let pCubeId = parseInt(this.$route.query.pCubeId)||this.getPid(this.cubes,this.cubeId);
            let obj={};
            if(pCubeId) {
                this.cubes.some(item=>{
                    if(item.cubeId===pCubeId) {
                        obj = item;
                        return true;
                    }
                })
            } else {
                this.cubes.some(item=>{
                    if(item.children) {
                        item.children.some(i=>{
                            if(i.cubeId===this.cubeId) {
                                obj = item;
                                return true;
                            }
                        })
                    }
                });                
            }
            return obj;
        },
        tableAreaStyle() {
            let obj = {};
            if(this.chartAreaSize===1) {
                obj.height='50%';
            } else if(this.chartAreaSize===2) {
                obj.height='calc(100% - 65px)';
            }
            return obj;
        },
        chartAreaStyle() {
            let obj = {};
            if(this.chartAreaSize===1) {
                obj = '50%';
            } else if(this.chartAreaSize===2) {
                obj.height='65px';
            }
            return obj;
        }
    },
    watch:{
        cubeId(value) {
            this.showNoDataCause = false;
            this.showHandsontableDom = false;
            this.getDimensions(value);
            if(chartHandle) {
                chartHandle.dispose();
            }
        },
        cubes(value) {
            if(value[0]&&!this.cubeId) {
                if(value[0].children&&value[0].children[0]) {
                    this.$router.push({
                        path:'/datas_data',
                        query:{
                            pCubeId:value[0].cubeId,
                            cubeId:value[0].children[0].cubeId
                        }
                    });
                } else {
                    this.$router.push({
                        path:'/datas_data',
                        query:{
                            cubeId:value[0].cubeId,
                        }
                    });
                }
            }
        },
        chartAreaSize() {
            setTimeout(()=>{
                this.hotRef.render();
                chartHandle.resize();
            },20);
        },
    },
    created() {
        this.getDimensions(this.cubeId);
    },
    mounted() {
        window.onresize = ()=>{
            if(chartHandle) {
                chartHandle.resize();
            }
            if(this.hotRef) {
                this.resizeTable();
            }
        }
    },
    methods:{
        getPid(arr,id) {
            let pid = null;
            arr.some(item=>{
                if(item.cubeId===id) {
                    pid = item.pid;
                    return true;
                }
                if(!pid&&item.children) {
                    pid = this.getPid(item.children,id);
                }
            });
            return pid;
        },
        switchSider() {
            this.showSide = !this.showSide;
            if(this.showSide) {
                this.pageLayoutSiderStyle = pageLayoutSiderStyle;
            } else {
                this.pageLayoutSiderStyle = pageLayoutSiderHideStyle
            }
            if(this.hotRef) {
                this.resizeTable();
            }
        },
        resizeTable() {
            this.hotRef.destroy();
            let settings = createSettings(this.tableData,this.getIndicotor,this.commentCellsArr);
            let handsontableDom = this.$refs.handsontable;                
            this.hotRef = new Handsontable(handsontableDom, settings);
            setTimeout(()=>{
                this.hotRef.render();
            },500);
        },
        getDimensions(cubeId) {
            this.selectedCodes = {};//先把选中维度的父级数据清空
            this.$http.getDimensions({cubeId}).then(res=>{
                if(res&&res.dataletBO) {
                    this.allDimensions = res.dataletBO.allDimensions.dimensionVOList;
                    this.sheetId = res.dataletBO.sheetId;
                    this.freqId = res.dataletBO.freqId;
                    this.cubeInfo = res.dataletBO.cube;
                    let dims = getSessionItem('dims');
                    if(dims&&cubeId===dims.cubeId) {//存在已选择维度——跨库搜索、收藏夹
                        let checkedCodes = {};
                        dims.dimensionVOLst.forEach(item=>{
                            checkedCodes[item.codeName] = item.codes;
                        });
                        this.dimData = {
                            metaLefts:res.dataletBO.metaLefts,
                            metaTops:res.dataletBO.metaTops,
                            allDimensions:res.dataletBO.allDimensions.dimensionVOList,
                            checkedCodes:checkedCodes,
                        }
                        this.syncParams={...dims.controls};
                        this.syncParams.metaColumns = dims.metaColumn.join('-');
                        this.syncParams.metaRows = dims.metaRow.join('-');
                        this.syncParams.fix = dims.metaFixed.join('-');
                        this.syncParams.dims = JSON.stringify(dims.dimensionVOLst); 
                        this.syncParams.cubeId = this.cubeId;
                        this.syncParams.sheetId = this.sheetId;
                        this.getData(this.syncParams);
                    } else {         
                        let checkedCodes = {};
                        res.dataletBO.allDimensions.dimensionVOList.forEach(item=>{
                            checkedCodes[item.dimensionId] = [];
                        });
                        this.dimData = {
                            metaLefts:res.dataletBO.metaLefts,
                            metaTops:res.dataletBO.metaTops,
                            allDimensions:res.dataletBO.allDimensions.dimensionVOList,
                            checkedCodes:checkedCodes
                        }
                    }

                    if(this.hotRef) {
                        this.hotRef.destroy();
                        this.hotRef = null;
                    }
                }
            })
        },
        goSync() {    
            this.syncParams = setSyncParams();        
            this.syncParams.cubeId = this.cubeId;
            this.syncParams.sheetId = this.sheetId;
            let dimsCode = this.$refs.dimensions.checkedCodes;
            let dims = [];
            var hasBlankDim = false;
            for(let i in dimsCode) {
                let obj = {codeName:i,codes:dimsCode[i].sort()};
                dims.push(obj);
                if(dimsCode[i].length===0) {
                    hasBlankDim = true;
                }
            }
            if(hasBlankDim) {
                messageTips('所有维度均不得为空',2);
                return false;
            }
            this.syncParams.dims = JSON.stringify(dims);
            let metaColumns = this.$refs.dimensions.metaLeftsNames;
            let metaRows = this.$refs.dimensions.metaTopsNames;
            let fix = this.$refs.dimensions.fixedsNames;
            if(metaColumns.length===0||metaRows.length===0) {
                messageTips('行或列至少要有一个维度',2);
                return false;
            }
            this.syncParams.metaColumns = metaColumns.join('-');
            this.syncParams.metaRows = metaRows.join('-');
            this.syncParams.fix = fix.join('-');
            this.getData(this.syncParams);
        },
        getData(params) {
            this.spinning = true;
            this.$http.syncData(params).then(res=>{
                this.spinning = false;
                
                if(chartHandle) {
                    chartHandle.dispose();
                }
                if(!res.dataletBO) {
                    messageTips(res.message,1);
                    return false;
                }
                if(this.hotRef&&this.hotRef.container) {
                    this.hotRef.destroy();
                }
                let fixedLeft = params.metaColumns.split('-');
                let fixedTop = params.metaRows.split('-');                
                    
                if(!res.dataletBO.cellBOs) {
                    this.showNoDataCause = true;
                    return false;
                }
                this.showNoDataCause = false;
                this.showHandsontableDom = true;
                this.tableCells = res.dataletBO.cellBOs;
                this.conditionParams = null;
                let table = tableParse({
                    fixedColumnsLeft: fixedLeft.length,
                    fixedRowsTop:fixedTop.length,
                    values:res.dataletBO.cellBOs
                });
                this.commentCellsArr = [];
                res.dataletBO.cellBOs.forEach((item,key)=>{
                    item.forEach((i,k)=>{
                        if(i&&i.commentOuter) {
                            this.commentCellsArr.push(
                                {
                                    row:key,
                                    col:k,
                                    comment:{value:i.commentOuter}
                                }
                            )
                        }
                    })
                })
                this.sheetInfo = res.dataletBO.sheetInfo;
                if(res.dataletBO.selectDimensions.dimParentCodeList) {
                    let obj = {}
                    res.dataletBO.selectDimensions.dimParentCodeList.forEach(item=>{
                        obj = {...obj,...item}
                    });    
                    this.selectedCodes = obj;
                }
                table.sort = this.tableSort;
                table.s4 = this.syncParams.s4;
                this.tableData = table;
                let settings = createSettings(table,this.getIndicotor,this.commentCellsArr);
                settings.afterSelectionEnd = this.afterTableSelectionEnd;
                
                setTimeout(()=>{
                    let handsontableDom = this.$refs.handsontable;
                    this.hotRef = new Handsontable(handsontableDom, settings);
                    this.createChart('bar');
                    this.hotRef.render();
                },30)
            });
        },
        tableSort(col,str) {
            let orderStr;
            if(str==='gt') {
                orderStr = '';
            } else if(str=='lt') {
                orderStr = 'gt';
            } else {
                orderStr = 'lt';
            }
            let s4 = {orderStr,index:col};
            this.syncParams.s4 = s4;
            this.getData(this.syncParams);
        },
        rotate() {
            let metaLefts = this.$refs.dimensions.metaLeftsNames;
            let metaTops = this.$refs.dimensions.metaTopsNames;
            let fixeds = this.$refs.dimensions.fixedsNames;
            let dimsCode = this.$refs.dimensions.checkedCodes;
            let rows = 1;
            for(let i in dimsCode) {
                if(metaLefts.indexOf(i)!==-1) {
                    rows = rows*dimsCode[i].length;
                }
            }
            if(rows>255) {
                messageTips('不支持大于255列的数据',2);
                return false;
            }
            this.dimData = {
                metaLefts:metaTops,
                metaTops:metaLefts,
                fixeds:fixeds,
                allDimensions:this.allDimensions,
                checkedCodes:dimsCode
            }
            setTimeout(()=>{
                this.goSync();
            },200);
        },
        filterFn() {
            this.filterVisible = Math.random();
        },
        highLightFn() {
            this.highLightVisible = Math.random();
        },
        conditionStyleFn() {
            this.conditionStyleVisible = Math.random();
        },
        cellFormatFn() {
            this.cellFormatVisible = Math.random();
        },
        tableCalculateFn() {
            this.tableCalculateVisible = Math.random();
        },
        GrowthRateFn() {
            let metaRows = this.$refs.dimensions.metaTopsNames;
            if(!(metaRows.length===1&&metaRows[0]==='TIME')) {
                messageTips('请将时间维度放在列上，且保证列上仅有时间维度。',2);
                return false;
            }
            this.GrowthRateVisible = Math.random();
        },
        userFunctionFn() {
            this.UserFunctionVisible = Math.random();
        },
        AnalysisEightTowFn() {
            this.AnalysisEightTowVisible = Math.random();
        },
        collectFn() {
            this.collectVisible = Math.random();
        },
        addSeriesFn() {
            let metaRows = this.$refs.dimensions.metaTopsNames;
            if(!(metaRows.length===1&&metaRows[0]==='TIME')) {
                messageTips('请将时间维度单独放置在列上，其他维度放置在行或固定区域',2);
                return false;
            }
            this.addSeriesVisible = Math.random();
        },
        scatterFn() {
            this.scatterSetVisible = Math.random();
        },
        windowApply(data) {
            if(data.name==='filterApply') {
                this.syncParams.s1 = data.s1;
                this.getData(this.syncParams);
            }
            if(data.name==='highLight') {
                this.syncParams.s5 = data.s5;
                this.getData(this.syncParams);
            }
            if(data.name==='tableCalculate') {
                this.syncParams.s2 = data.s2;
                this.getData(this.syncParams);
            }
            if(data.name==='analysisET') {
                this.syncParams.s3 = data.s3;
                this.getData(this.syncParams);
            }
        },
        setConditionStyle(data) {
            let area = this.hotRef.getSelected();
            let rows = this.syncParams.metaRows.split('-');
            let cols = this.syncParams.metaColumns.split('-');
            if(!area) {
                let data = this.hotRef.getData();
                area = [rows.length,cols.length,data.length-1,data[0].length-1];
            }
            let p1,p2;
            if((typeof area[0])=='object') {
                p1 = area[0][0]+','+area[0][1];
                p2 = area[0][2]+','+area[0][3];
            } else {
                p1 = area[0]+','+area[1];
                p2 = area[2]+','+area[3];
            }
            this.spinning = true;
            this.conditionParams = {...data.data};
            var params = {sheetId:this.sheetId,uid:'201102254',p1:p1,p2:p2,...data.data};
            this.$http.setConditionStyle(params).then(res=>{
                if(!res.dataletBO) {
                    return false;
                }
                this.tableCells = res.dataletBO.cellBOs;
                let table = tableParse({
                    fixedColumnsLeft: cols.length,
                    fixedRowsTop:rows.length,
                    values:res.dataletBO.cellBOs
                });
                this.commentCellsArr = [];
                res.dataletBO.cellBOs.forEach((item,key)=>{
                    item.forEach((i,k)=>{
                        if(i&&i.commentOuter) {
                            this.commentCellsArr.push(
                                {
                                    row:key,
                                    col:k,
                                    comment:{value:i.commentOuter}
                                }
                            )
                        }
                    })
                });
                this.sheetInfo = res.dataletBO.sheetInfo;
                table.sort = this.tableSort;
                table.s4 = this.syncParams.s4;
                this.tableData = table;
                let settings = createSettings(table,this.getIndicotor,this.commentCellsArr);
                settings.afterSelectionEnd = this.afterTableSelectionEnd
                let handsontableDom = this.$refs.handsontable;
                if(this.hotRef) {
                    this.hotRef.destroy();
                }
                this.hotRef = new Handsontable(handsontableDom, settings);
                setTimeout(()=>{
                    this.hotRef.render();
                },10);
                this.spinning = false;
            })
        },
        applyCellFormat(data) {
            let rows = this.syncParams.metaRows.split('-');
            let cols = this.syncParams.metaColumns.split('-');
            let table = tableParse({
                fixedColumnsLeft: cols.length,
                fixedRowsTop:rows.length,
                values:this.tableCells,
            });
            table.floatNum=data.data.floatNum;
            table.moneyFormat=data.data.moneyFormat;
            table.percentFormat=data.data.percentFormat;
            table.thousandsSeparator=data.data.thousandsSeparator;
            table.needSetCellFormatRows=data.data.selectedRows;
            table.sort = this.tableSort;
            table.s4 = this.syncParams.s4;
            this.tableData = table;
            let settings = createSettings(table,this.getIndicotor,this.commentCellsArr);
            settings.afterSelectionEnd = this.afterTableSelectionEnd
            let handsontableDom = this.$refs.handsontable;
            if(this.hotRef) {
                this.hotRef.destroy();
            }
            this.hotRef = new Handsontable(handsontableDom, settings);
            setTimeout(()=>{
                this.hotRef.render();
            },10);
        },
        setGrowthRate(data) {
            this.$http.setGrowthRate(data.data).then(res=>{
                if(res.success===false) {
                    messageTips(res.message,1);
                    return false;
                }
                let rows = this.syncParams.metaRows.split('-');
                let cols = this.syncParams.metaColumns.split('-');                
                this.tableCells = res.dataletBO.cellBOs;
                this.conditionParams = null;
                let table = tableParse({
                    fixedColumnsLeft: cols.length,
                    fixedRowsTop:rows.length,
                    values:res.dataletBO.cellBOs
                });
                this.commentCellsArr = [];
                res.dataletBO.cellBOs.forEach((item,key)=>{
                    item.forEach((i,k)=>{
                        if(i&&i.commentOuter) {
                            this.commentCellsArr.push(
                                {
                                    row:key,
                                    col:k,
                                    comment:{value:i.commentOuter}
                                }
                            )
                        }
                    })
                });
                table.sort = this.tableSort;
                table.s4 = this.syncParams.s4;
                this.tableData = table;
                let settings = createSettings(table,this.getIndicotor,this.commentCellsArr);
                settings.afterSelectionEnd = this.afterTableSelectionEnd
                let handsontableDom = this.$refs.handsontable;
                if(this.hotRef) {
                    this.hotRef.destroy();
                }
                this.hotRef = new Handsontable(handsontableDom, settings);                
                this.createChart(this.chartType);
                setTimeout(()=>{
                    this.hotRef.render();
                },10);
            });
        },
        setUserFunction(data) {
            let params = {...data.data}
            this.$http.userDefinedFn(params).then(res=>{
                let rows = this.syncParams.metaRows.split('-');
                let cols = this.syncParams.metaColumns.split('-');                
                this.tableCells = res.cellBOs;
                this.conditionParams = null;
                let table = tableParse({
                    fixedColumnsLeft: cols.length,
                    fixedRowsTop:rows.length,
                    values:res.cellBOs
                });

                this.commentCellsArr = [];
                res.dataletBO.cellBOs.forEach((item,key)=>{
                    item.forEach((i,k)=>{
                        if(i&&i.commentOuter) {
                            this.commentCellsArr.push(
                                {
                                    row:key,
                                    col:k,
                                    comment:{value:i.commentOuter}
                                }
                            )
                        }
                    })
                });

                table.sort = this.tableSort;
                table.s4 = this.syncParams.s4;
                this.tableData = table;
                let settings = createSettings(table,this.getIndicotor,this.commentCellsArr);
                settings.afterSelectionEnd = this.afterTableSelectionEnd
                let handsontableDom = this.$refs.handsontable;
                if(this.hotRef) {
                    this.hotRef.destroy();
                }
                this.hotRef = new Handsontable(handsontableDom, settings);                
                this.createChart(this.chartType);
                setTimeout(()=>{
                    this.hotRef.render();
                },10);
            })
        },
        clearResult() {
            var that = this;
            this.$confirm({
                title: '提示',
                content: '您确定要清除结果吗？',
                okText:'继续',
                cancelText:'取消',
                centered:true,
                onOk() {
                    that.goSync();
                },
                onCancel() {},
            });            
        },
        afterTableSelectionEnd() {
            if(!this.chartType||this.chartType==='scatter') {
                return false;
            }
            let arr = this.hotRef.getSelected();
            let rows = [];
            let cols = [];
            arr.forEach(item=>{
                if(item[0]<item[2]) {
                    for(let i= item[0];i<=item[2];i++) {
                        if(rows.indexOf(i)===-1) {
                            rows.push(i);
                        }
                    }
                } else {
                    for(let i= item[2];i<=item[0];i++) {
                        if(rows.indexOf(i)===-1) {
                            rows.push(i);
                        }
                    }
                }
                if(item[1]<item[3]) {
                    for(let i= item[1];i<=item[3];i++) {                    
                        if(cols.indexOf(i)===-1) {
                            cols.push(i);
                        }
                    }
                } else {
                    for(let i= item[3];i<=item[1];i++) {                    
                        if(cols.indexOf(i)===-1) {
                            cols.push(i);
                        }
                    }
                }
            });
            let fixedLeft = this.syncParams.metaColumns.split('-');
            let fixedTop = this.syncParams.metaRows.split('-');

            rows.sort(sequence);
            cols.sort(sequence);
            if(cols[cols.length-1]<fixedLeft.length||rows[rows.length-1]<fixedTop.length) {
                return false;
            }

            let params = {
                indexs:rows.join(','),yindexs:cols.join(','),
                sheetId:this.sheetId
            }
            this.$http.getChartData(params).then(res=>{
                if(res) {
                    if(chartHandle) {
                        chartHandle.dispose();
                    }
                    chartHandle = echarts.init(this.$refs.chart);
                    setCharts(chartHandle,this.chartType,res,this.showChartLegend,this.showChartDataLabel);
                }
            });
        },
        createChart(type) {
            if(!this.hotRef) {
                return false;
            }
            if(chartHandle) {
                chartHandle.dispose();
            }
            chartHandle = echarts.init(this.$refs.chart);
            if(!type&&this.userChartOption) {
                chartHandle.setOption(this.userChartOption)
                return false;
            }
            this.chartType = type;

            if(this.hotRef.getSelected()) {
                this.afterTableSelectionEnd();
                return false;
            }
            
            let tableData = this.hotRef.getData();
            let fixedLeft = this.syncParams.metaColumns.split('-');
            let fixedTop = this.syncParams.metaRows.split('-');
            let indexs = [],yindexs = [];
            tableData.forEach((item,key)=>{
                if(key>=fixedTop.length) {
                    indexs.push(key)
                }
            });
            tableData[0].forEach((item,key)=>{
                if(key>=fixedLeft.length) {
                    yindexs.push(key)
                }
            });
            let params = {
                indexs:indexs.join(','),yindexs:yindexs.join(','),
                sheetId:this.sheetId
            }
            this.$http.getChartData(params).then(res=>{
                if(res) {
                    setCharts(chartHandle,type,res,this.showChartLegend,this.showChartDataLabel);
                }
            });
        },
        scatterSetCtrl(data) {
            if(chartHandle) {
                chartHandle.dispose();
            }
            if(bigChartHandle) {
                bigChartHandle.dispose();
            }
            chartHandle = echarts.init(this.$refs.chart);
            this.chartType = 'scatter';
            this.scatterData = data;
            if(this.bigChartVisible) {
                setCharts(bigChartHandle,'scatter',data,this.showChartLegend,this.showChartDataLabel);
            } else {
                setCharts(chartHandle,'scatter',data,this.showChartLegend,this.showChartDataLabel);
            }
        },
        showChartLegendFn() {
            if(!chartHandle) {
                return false;
            }
            this.showChartLegend = !this.showChartLegend;
            if(this.bigChartVisible) {
                if(this.chartType) {
                    showChartLegendFn(bigChartHandle,this.showChartLegend);
                } else {
                    this.userChartOption.legend.show = this.showChartLegend;
                    bigChartHandle.setOption(this.userChartOption);
                }
            } else {
                if(this.chartType) {
                    showChartLegendFn(chartHandle,this.showChartLegend);
                } else {
                    this.userChartOption.legend.show = this.showChartLegend;
                    chartHandle.setOption(this.userChartOption);
                }
            }
        },
        showChartDataLabelFn() {
            if(!chartHandle) {
                return false;
            }
            this.showChartDataLabel = !this.showChartDataLabel;
            if(this.bigChartVisible) {
                if(!this.chartType) {
                    this.userChartOption.series.forEach(item=>{
                        item.label.show = this.showChartDataLabel;
                    })
                    bigChartHandle.setOption(this.userChartOption);
                } else {
                    showChartDataLabelFn(bigChartHandle,this.showChartDataLabel,this.chartType);
                }                
            } else {
                if(!this.chartType) {
                    this.userChartOption.series.forEach(item=>{
                        item.label.show = this.showChartDataLabel;
                    })
                    chartHandle.setOption(this.userChartOption);
                } else {
                    showChartDataLabelFn(chartHandle,this.showChartDataLabel,this.chartType);
                }
            }            
        },
        userChartFn() {
            let tableData = this.hotRef.getData();            
            let fixedLeft = this.syncParams.metaColumns.split('-');
            let fixedTop = this.syncParams.metaRows.split('-');
            this.userChartData = {
                tableData,
                fixedLeft:fixedLeft.length,
                fixedTop:fixedTop.length,
            }
            this.userChartVisible = Math.random();
        },
        getIndicotor(data) {
            this.infoParams = data;
            this.infoParams.cubeId = this.cubeId;
            this.indicatorInfoVisible = Math.random();
        },
        setUserChart(data) {
            if(chartHandle) {
                chartHandle.dispose();
            }
            if(bigChartHandle) {
                chartHandle.dispose();
            }
            chartHandle = echarts.init(this.$refs.chart);
            this.showChartLegend = data.showLegend;
            this.showChartDataLabel = data.showLabel;            
            this.chartType = null;
            this.userChartOption = data.option;
            if(this.bigChartVisible) {
                bigChartHandle.setOption(data.option);
            } else {
                chartHandle.setOption(data.option);
            }
        },
        goCollection(data) {     
            let dimsCode = this.$refs.dimensions.checkedCodes;
            let dims = [];
            for(let i in dimsCode) {
                let obj = {codeName:i,codes:dimsCode[i].sort()};
                dims.push(obj);
            }
            let metaColumn = this.$refs.dimensions.metaLeftsNames;
            let metaRow = this.$refs.dimensions.metaTopsNames;
            let metaFixed = this.$refs.dimensions.fixedsNames;
            let content = {
                dimensionVOLst:dims,
                metaColumn,
                metaRow,
                metaFixed,
                sheetId:this.sheetId,
                cubeId:this.cubeId,
                controls:{
                    s1:this.syncParams.s1,
                    s2:this.syncParams.s2,
                    s3:this.syncParams.s3,
                    s4:this.syncParams.s4,
                    s5:this.syncParams.s5,
                }
            }

            let params = {
                content:JSON.stringify(content),
                name:data.name,
                cubeId:this.cubeId,
                parentId:data.parentId?data.parentId:0
            }
            this.$http.goCollection(params).then(res=>{
                if(res) {
                    if(res.success) {
                        messageTips('收藏成功',3);
                    } else {
                        messageTips(res.message,1);
                    }
                }
            })
            
        },
        goDownload() {
            this.$http.checkDownload({sheetId:this.sheetId}).then(res=>{
                if(res) {
                    if(res.canDownload) {                        
                        let params = {
                            sheetId:this.sheetId,
                            sid:getCookie('bd_sid'),
                            sign:res.sign
                        }
                        window.location.href = config.downloadTable+'?'+objToUrl(params);
                    } else {
                        messageTips(res.message,1);
                    }
                }
            })
            
        },
        downloadImg(type) {
            let element;
            if(type===1) {
                element = this.$refs.chart;
            } else if(type===2) {
                element = this.$refs.bigChart;
            }
            html2canvas(element,{
                onrendered: function(canvas) {
                    document.body.appendChild(canvas);
                },
            }).then((canvas) => {
                let dataURL = canvas.toDataURL('image/png');
                savePicture(dataURL);
            });
        },
        checkBigChart() {
            this.bigChartVisible = true;
            this.selectedSeries = [];            
            axios.all([this.getRows(),this.getCols()]).then(axios.spread((data,data2)=>{                
                this.selectedSeries = data.map(item=>{
                    item.key = item.key.replace(/\|/,'-');
                    return item.index
                });
                this.series = data;
                this.cols = data2; 
                this.setChartData();                   
            }));
        },

        getRows() {
            let params = {sheetId:this.sheetId}
            return this.$http.getVars(params)
        },
        getCols() {
            let params = {sheetId:this.sheetId}
            return this.$http.getColumnsMsg(params)
        },
        setChartData() {
            let datas = this.getChartDatas();
            bigChartHandle = echarts.init(this.$refs.bigChart);
            if(this.chartType) {
                if(this.chartType==='scatter') {                
                    setBigChart(bigChartHandle,'scatter',this.scatterData,this.showChartLegend,this.showChartDataLabel);
                } else {                    
                    let xNames = this.cols.map(item=>item.key);
                    let yNames = this.series.map(item=>item.key);
                    setBigChart(bigChartHandle,this.chartType,{xNames,yNames,datas},this.showChartLegend,this.showChartDataLabel);
                }                
            } else {
                bigChartHandle.setOption(this.userChartOption);
            }
        },
        createBigChart(type) {
            if(bigChartHandle) {
                bigChartHandle.dispose();
            }
            this.chartType = type;
            bigChartHandle = echarts.init(this.$refs.bigChart);
            let xNames = this.cols.map(item=>item.key);
            let yNames = this.series.map(item=>item.key);
            let datas = this.getChartDatas();
            let fixedTop = this.syncParams.metaRows.split('-');
            
            let res = {
                xNames,
                yNames:[],
                datas:[]
            }
            let arr;
            if(type==='ring'||type==='pie') {
                if(typeof this.selectedSeries==='object') {
                    this.selectedSeries = this.selectedSeries[0];
                }
                arr = [this.selectedSeries-fixedTop.length];
            } else {
                if(typeof this.selectedSeries!=='object') {
                    this.selectedSeries = [this.selectedSeries];
                }
                arr = this.selectedSeries.map(item=>(item-fixedTop.length));
            }            
            arr.forEach(item=>{
                res.yNames.push(yNames[item]);
                res.datas.push(datas[item]);
            });
            setBigChart(bigChartHandle,type,res,this.showChartLegend,this.showChartDataLabel);
        },
        changeSeries() {
            this.createBigChart(this.chartType);
        },

        getChartDatas() {
            let data = [];
            let tableData = this.hotRef.getData(); 
            let fixedLeft = this.syncParams.metaColumns.split('-');
            let fixedTop = this.syncParams.metaRows.split('-');
            tableData.forEach((item,key)=>{
                if(key>fixedTop.length-1) {
                    let arr = [];
                    item.forEach((i,k)=>{
                        if(k>fixedLeft.length-1) {
                            arr.push(i);
                        }
                    });
                    data.push(arr);
                }
            });
            return data;
        },
        selectDimCode(data) {
            let obj = {...this.dimData};
            obj.checkedCodes = data;
            this.dimData = {...obj};
        },
        makeMap() {
            let metaTops = this.dimData.metaTops;
            if(metaTops.length>1||metaTops[0]!=='TIME') {
                messageTips('请按照时间序列生成表格',2);
                return false;
            }
            this.$http.syncData(this.syncParams).then(res=>{
                if(res&&res.dataletBO&&res.dataletBO.cellBOs) {
                    this.mapData = {
                        cellBOs:res.dataletBO.cellBOs,
                        metaLefts:this.dimData.metaLefts
                    };
                    this.mapVisible = Math.random();
                } else {
                    messageTips(res.message,1);
                }
            });
        },
        dimensionDragEnd(data) {
            this.dimData = data;
        },
        closeBigChart() {
            this.bigChartVisible=false;
            bigChartHandle.dispose();
            if(this.chartType) {
                if(this.chartType==='scatter') {
                    setCharts(bigChartHandle,'scatter',this.scatterData,this.showChartLegend,this.showChartDataLabel);
                } else {
                    this.createChart(this.chartType);
                }
                
            } else {
                chartHandle.setOption(this.userChartOption);
            }            
        },
        runPlayChart(flag) {
            this.chartPlay = !flag;
            playBigChart(bigChartHandle,flag)
        }
    }
}
</script>

<style lang="scss">
    @import '../../scss/datas/data.scss';
</style>