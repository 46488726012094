<template>
<a-spin tip="Loading..." :spinning="spinning">
    <a-layout style="height:100%;">
        <a-layout-sider :style="pageLayoutSiderStyle" v-if="!jobId">
            <div class="sider-inner" :style="{width:showSide?'100%':'10px'}">
                <div class="switch" @click="switchSider">
                    <a-icon type="backward" v-if="showSide" />
                    <a-icon type="forward" v-else />
                </div>
                <div class="list" v-if="showSide">
                    <a-tree
                        :expanded-keys="expandedKeys"
                        :auto-expand-parent="autoExpandParent"
                        @expand="onExpand"
                        :tree-data="treeData"
                        @select="onSelect"
                        :replaceFields="{children:'children', title:'cubeNameZh', key:'cubeId', value: 'cubeId'}"
                        :selectedKeys="selectedKeys"
                    />
                </div>
            </div>
        </a-layout-sider>
        <a-layout-content>
            <div class="platform">
                <div class="module-name">
                    <div class="name">{{cubeName}}</div>
                </div>
                <div class="platform-layout">
                    <a-layout style="height:100%">
                        <a-layout-header style="height:68px;background:#fff;">
                            <div class="toolbar">
                                <div class="ctrl-btn">
                                    
                                    <div class="item" @click="saveData()" v-if="(jobId&&editable===1)||!jobId">
                                        <span class="iconfont icon-baocun"></span>
                                        <div class="name">保存</div>
                                    </div>
                                    <div class="item" @click="filterFn($event)" :class="{active:syncParams.s1.dealStr}">
                                        <a-icon type="filter"  class="iconfont" />
                                        <div class="name">筛选</div>
                                    </div>
                                    <div class="upload-btn2" v-if="hotRef">
                                        <a-upload
                                            name="file"
                                            :multiple="false"
                                            :action="uploadUrl"
                                            :showUploadList="false"
                                            @change="handleChangeUpload"
                                        >
                                            <div class="item">
                                                <span class="iconfont icon-daoru"></span>
                                                <div class="name">导入</div>
                                            </div>
                                        </a-upload>
                                    </div>

                                    <div class="item" v-else @click="beforeUpload">
                                        <span class="iconfont icon-daoru"></span>
                                        <div class="name">导入</div>
                                    </div>

                                    <div class="item" @click="exportCsv">
                                        <span class="iconfont icon-daochu"></span>
                                        <div class="name">导出</div>
                                    </div>
                                    <div class="item" @click="undo" :class="{'disabled':!hotRef||!hotRef.isUndoAvailable||!hotRef.isUndoAvailable()}">
                                        <span class="iconfont icon-houtui"></span>
                                        <div class="name">上一步</div>
                                    </div>
                                    <div class="item" @click="redo" :class="{'disabled':!hotRef||!hotRef.isRedoAvailable||!hotRef.isRedoAvailable()}">
                                        <span class="iconfont icon-qianjin"></span>
                                        <div class="name">下一步</div>
                                    </div>
                                    <div class="item" @click="refresh">
                                        <span class="iconfont icon-icon_refresh"></span>
                                        <div class="name">刷新</div>
                                    </div>
                                    <div class="item" @click="rotate">
                                        <span class="iconfont icon-hangliezhuanzhi"></span>
                                        <div class="name">行列转置</div>
                                    </div>
                                    <div class="item" @click="showCommentDrawer">
                                        <a-icon type="message" class="iconfont" />
                                        <div class="name">添加批注</div>
                                    </div>
                                    <div class="item" @click="showCode" :class="{active:isShowCode}">
                                        <a-icon type="copyright" class="iconfont" />
                                        <div class="name">code</div>
                                    </div>
                                    <div class="item" @click="submitJob" v-if="jobId">
                                        <a-icon type="to-top" class="iconfont" />
                                        <div class="name">提交任务</div>
                                    </div>
                                    <a-input-search ref="searchValue" type="search" style="display:inline-block;width:120px;position:relative;top:-10px;" placeholder="Search" size="small" @change="searchValue" />
                                </div>
                            </div>
                        </a-layout-header>
                        <a-layout style="height:100%">
                            <a-layout-sider :style="{width:'244px',flex:'0 0 244px', maxWidth:'244px',minWidth:'244px', background:'#fff',borderRight:'1px solid #ddd'}">
                                <div class="steps" style="padding:0 10px;">
                                    <a-button type="primary" size="small" ghost :disabled="currentStep<=0" @click="preStep"><a-icon type="caret-left" />上一步</a-button>
                                    <a-button type="primary" size="small" ghost :disabled="steps.length===0||currentStep===steps.length-1" @click="nextStep" style="float:right;">下一步<a-icon type="caret-right" /></a-button>
                                </div>
                                <Dimensions  
                                :dimData="dimData" 
                                :cubeId="cubeId" 
                                @canSync="canSync" 
                                :sheetId="sheetId" 
                                :freqId="freqId" 
                                ref="dimensions" 
                                @selectDimCode="selectDimCode" 
                                @dimensionDragEnd="dimensionDragEnd" 
                                :tagListTree="tagListTree"
                                :selectedCodes="selectedCodes"
                                :isInput="true" />
                                <div class="source" style="padding:30px 15px 0 10px;">
                                    <h3 style="font-size:15px;font-weight:bold;">数据来源</h3>
                                    <a-select style="width:100%" placeholder="请选择数据来源" :value="sourceId" @change="changeSource">
                                        <a-icon slot="suffixIcon" type="caret-down" />
                                        <a-select-option v-for="item in sourceList" :key="item.sourceId" :value="item.sourceId">{{item.sourceName}}</a-select-option>
                                    </a-select>
                                </div>
                                <div class="sync-btn">
                                    <a-button type="primary" @click="goSync()">查询数据</a-button>
                                </div>
                            </a-layout-sider>
                            <a-layout-content style="padding:0 10px;">
                                <div style="widht:100%;height:100%" class="input-data" ref="handsontable" v-show="hotRef"></div>   
                                <div v-show="!hotRef">
                                    <div class="cubeInfo" v-if="cubeInfo">
                                        <dl>
                                            <dt>{{cubeInfo.cubeFnameZh}}</dt>
                                            <dd><div class="label">数据量：</div>{{cubeInfo.totalNum}}<div class="clear"></div></dd>
                                            <dd><div class="label">数据起止时间：</div>{{cubeInfo.startDate}}~{{cubeInfo.lastDate}}<div class="clear"></div></dd>
                                            <dd><div class="label">数据频度：</div><span v-if="cubeInfo.freqId===1">年度</span><span v-if="cubeInfo.freqId===3">季度</span><span v-if="cubeInfo.freqId===4">月度</span><div class="clear"></div></dd>
                                            <dd><div class="label">最新更新时间：</div>{{cubeInfo.lstmodDate}}<div class="clear"></div></dd>
                                            <dd><div v-html="cubeInfo.descZh"></div></dd>
                                        </dl>
                                    </div>
                                    <a-alert message="无数据！" banner v-show="showBlank" />
                                </div>                             
                            </a-layout-content>
                        </a-layout>
                    </a-layout>
                </div>
            </div>

            <a-drawer
                title="添加批注"
                placement="right"
                :closable="false"
                :visible="drawerVidsible"
                :width="500"
                >
                <h3>内部批注</h3>
                <a-textarea
                v-model="innerComment"
                placeholder=""
                :auto-size="{ minRows: 5, maxRows: 10 }"
                />
                <div style="height:40px;"></div>
                <h3>外部批注</h3>
                <a-textarea
                v-model="outComment"
                placeholder=""
                :auto-size="{ minRows: 5, maxRows: 10 }"
                closable
                />

                <div class="btns">
                    <a-button @click="drawerVidsible=false">取消</a-button>
                    <a-button type="primary" @click="addComments">确定</a-button>
                </div>
            </a-drawer>

            <Filters :visible="filterVisible" @filterApply="windowApply" :s1="syncParams.s1" />
        </a-layout-content>
    </a-layout>
</a-spin>
</template>

<script>
import {pageLayoutSiderStyle,pageLayoutSiderHideStyle} from '../../until/global_style';
import 'handsontable/dist/handsontable.full.css';
import Handsontable from 'handsontable'; 
import Dimensions from '../../components/Dimensions.vue';
import {createSettings, tableParse} from '../../until/handsontableSetting';
import { delLocalItem, getCookie, getLocalItem, messageTips, objToUrl, setLocalItem } from '../../until/tools';
import config from '../../until/configs';
// import { setCube } from '../../until/data_tools'; 
import Filters from '../datas/components/Filters.vue';
import axios from 'axios';
function setSyncParams() {
    return {
        s1:{dealStr:''},
        s2:{method:'',type:''},
        s3:{index8020:'',fontStyle8020:'',bgColor8020:''},
        s4:{orderStr:'',index:''},
        s5:{dealStr:'',showBackColor:'',showFontStyle:''},
    }
}
export default {
    name:'Cube',
    components:{Dimensions,Filters},
    data() {
        return {
            pageLayoutSiderStyle:pageLayoutSiderStyle,
            showSide:true,
            expandedKeys: [],
            autoExpandParent: true,
            treeData:[],
            hotRef:null,
            dimData:null,
            sheetId:null,
            newSheetId:null,
            allDimensions:[],
            cubeInfo:null,
            spinning:false,
            newYear:null,
            editStatus:true,
            tableData:null,
            drawerVidsible:false,
            innerComment:'',
            outComment:'',
            commentCells:{},
            commentCellsArr:[],
            timer:null,
            cubeName:'',
            freqId:1,
            syncParams:setSyncParams(),
            filterVisible:0,
            selectedArea:[],
            steps:[],
            currentStep:-1,
            sourceList:[],
            sourceId:undefined,
            isShowCode:false,
            tagListTree:{},
            selectedCodes:{},
            isTableChanged:false,
            showBlank:false,
            tableCells:[],
            proTableData:[]
        }
    },
    computed:{
        cubeId() {
            let id = this.$route.query.cubeId;
            if(id) {
                return parseInt(id);
            }
            return 0;
        },
        selectedKeys() {
            let cubeId = parseInt(this.$route.query.cubeId);
            return [cubeId];
        },
        uploadUrl() {
            return config.inputsImportFile+'?sid='+getCookie('bd_sid')+'&sheetId='+this.sheetId;
        },
        jobId() {
            return this.$route.query.jobId;
        },
        editable() {
            if(this.$route.query.editable) {
                return parseInt(this.$route.query.editable);
            }
            return 0;
        },
    },
    watch: {
        cubeId() {

            this.initData().then(()=>{
                this.sourceId = undefined;
                this.getSource();
            });
        },
        selectedArea(value,oldValue) {
            let data = JSON.parse(JSON.stringify(this.hotRef.getData()));
            if(data&&oldValue&&oldValue[0]) {                
                let rowNum,colNum;
                if((typeof oldValue[0])=='object') {
                    rowNum = oldValue[0][0]
                    colNum = oldValue[0][1];
                } else {
                    rowNum = oldValue[0]
                    colNum = oldValue[1];
                }
                if(this.proTableData[rowNum][colNum]!==data[rowNum][colNum]) {
                    console.log('aaa')
                    this.tableData.fontStyles[rowNum][colNum].fontStyle.fontWeight = 'bold';
                    this.tableData.data = data;
                    let table = JSON.parse(JSON.stringify(this.tableData));
                    let settings = createSettings(table,null,this.commentCellsArr);
                    settings.rowHeaders = true;
                    this.hotRef.render();
                    this.isTableChanged = true;
                    // this.hotRef.selectCell(0,0);
                }
            }
            this.hotRef.render();
            // this.hotRef.selectCell(0,0);
        }
    },
    methods:{
        // afterChange() {
        //     this.isTableChanged = true;
        // },
        changeSource(e) {
            if(this.isTableChanged) {
                var that = this;
                this.$confirm({
                    title:'提示',
                    content:'您有数据尚未保存，保存后方可切换来源，是否保存数据？',
                    centered:true,
                    okText:'保存数据',
                    cancelText:'取消切换',
                    onOk() {
                        that.saveData(false,true).then(res=>{
                            if(res) {
                                that.sourceId = e;
                                that.goSync();
                            }
                        })
                    }
                });
            } else {
                this.sourceId = e;
            }
        },
        searchValue(e) {            
            const search = this.hotRef.getPlugin('search');
            search.query(e.target.value);
            this.hotRef.render();
            // this.hotRef.selectCell(0,0);
        },
        switchSider() {
            this.showSide = !this.showSide;
            if(this.showSide) {
                this.pageLayoutSiderStyle = pageLayoutSiderStyle;
            } else {
                this.pageLayoutSiderStyle = pageLayoutSiderHideStyle
            }
            if(this.hotRef) {
                this.resizeTable();
            }
        },

        resizeTable() {
            this.hotRef.destroy();
            let settings = createSettings(this.tableData,this.getIndicotor,this.commentCellsArr);
            settings.rowHeaders = true;
            let handsontableDom = this.$refs.handsontable;                
            this.hotRef = new Handsontable(handsontableDom, settings);
            setTimeout(()=>{
                this.hotRef.render();
                // this.hotRef.selectCell(0,0);
            },500);
        },

        onExpand(expandedKeys) {
            this.expandedKeys = expandedKeys;
            this.autoExpandParent = false;
        },

        onSelect(selectedKeys, info) {
            if(info.node.dataRef.children&&info.node.dataRef.children.length>0) {
                info.node.onExpand()
                return false;
            } else {
                this.$router.push({
                    path:'/inputs_data',
                    query:{
                        cubeId:info.node.eventKey
                    }
                })
            }
        },
        beforeUpload() {
            if(!this.hotRef) {
                messageTips('请先生成对应的数据表格',2);
                return false;
            }
        },
        handleChangeUpload({file}) {
            if (file.status === 'done') {
                if(file.response) {
                    let res = file.response;
                    if(res.dataletBO&&res.dataletBO.cellBOs) {
                        messageTips(`${file.name} 上传成功。`,3);
                        let table = tableParse({
                            fixedColumnsLeft: res.dataletBO.metaLefts.length,
                            fixedRowsTop:res.dataletBO.metaTops.length,
                            values:res.dataletBO.cellBOs
                        },this.isShowCode);
                        this.tableData = JSON.parse(JSON.stringify(table));
                        let settings = createSettings(table,null,this.commentCellsArr);
                        settings.rowHeaders = true;
                        let handsontableDom = this.$refs.handsontable;
                        if(this.hotRef) {
                            this.hotRef.destroy();
                        }
                        this.hotRef = new Handsontable(handsontableDom, settings);

                        setTimeout(()=>{
                            this.hotRef.render();
                            // this.hotRef.selectCell(0,0);
                        },10)
                    } else {
                        
                        messageTips(res.message,1);
                    }
                    
                }
            } else if (file.status === 'error') {
                messageTips(`${file.name} 上传失败。`,1);
            }
        },

        getTreeList(arr,pid,result) {
            arr.forEach((item)=>{
                item.value = item.cubeId;
                item.key = item.cubeId;
                item.scopedSlots = { title: 'title' };
                item.title = item.cubeNameZh;
                item.titleCopy = item.cubeNameZh;
                if(item.pid===pid) {
                    if(!item.children) {
                        item.children = [];
                    }
                    item.children = this.getTreeList(arr,item.cubeId,item.children);
                    result.push(item);
                }
            });
            return result;
        }, 


        getTagTree(arr,pid) {
            let result = [];
            arr.forEach(item=>{
                if(item.parentId===pid) {
                    let children = this.getTagTree(arr,item.tagId);
                    if(children.length>0) {
                        item.children = children;
                    }
                    result.push(item);
                }
            });
            return result;
        },

        getDimensions(cubeId) {
            let jobId = this.$route.query.jobId;
            return this.$http.getDimensions({cubeId,isInput:1,jobId}).then(res=>{
                if(res&&res.dataletBO) {
                    let checkedCodes = {};
                    let ajaxArr = [],dimensionIds = [];
                    res.dataletBO.allDimensions.dimensionVOList.forEach(item=>{
                        checkedCodes[item.dimensionId] = [];
                        //获取维度标签
                        dimensionIds.push(item.dimensionId);
                        ajaxArr.push(this.$http.getTags({dimensionId:item.dimensionId,lang:1}));
                    });
                    return axios.all(ajaxArr).then(axios.spread((...res)=>{
                        let tagListTree = {};
                        res.forEach((item,key)=>{
                            tagListTree[dimensionIds[key]] = this.getTagTree(item.list,'-1');
                        })
                        this.tagListTree = tagListTree;
                    })).then(()=>{
                        this.dimData = {
                            metaLefts:res.dataletBO.metaLefts,
                            metaTops:res.dataletBO.metaTops,
                            allDimensions:res.dataletBO.allDimensions.dimensionVOList,
                            checkedCodes:checkedCodes
                        }
                        this.allDimensions = res.dataletBO.allDimensions.dimensionVOList;
                        this.sheetId = res.dataletBO.sheetId;
                        this.freqId = res.dataletBO.freqId;
                        this.cubeInfo = res.dataletBO.cube;
                        if(this.hotRef) {
                            this.hotRef.destroy();
                            this.hotRef = null;
                        }
                        this.cubeName = res.dataletBO.sheetInfo.sheetName;
                        return true;
                    });
                } else {
                    messageTips(res.message,1);
                }
                return res;
            });
        },
        goSync(flag) {
            var that = this;
            if(this.isTableChanged) {
                this.$confirm({
                    title:'提示',
                    content:'您有数据尚未保存，是否继续同步数据？',
                    centered:true,
                    okText:'继续',
                    cancelText:'取消',
                    onOk() {
                        that.selectedCodes = {};//先把选中维度的父级数据清空
                        that.goonSync(flag);
                    }
                });
            } else {
                this.selectedCodes = {};//先把选中维度的父级数据清空
                this.goonSync(flag);
            }
        },
        goonSync(flag) {
            let params = {
                s1:this.syncParams.s1,
                s2:{method:'',type:''},
                s3:{index8020:'',fontStyle8020:'',bgColor8020:''},
                s4:{orderStr:'',index:''},
                s5:{dealStr:'',showBackColor:'',showFontStyle:''},
                cubeId:this.cubeId,
                sheetId:this.sheetId,
                jobId:this.$route.query.jobId?this.$route.query.jobId:-1
            }
            if(!this.newSheetId) {
                this.newSheetId = Math.random();
            }
            params.newSheetId = this.newSheetId;
            let dimsCode = this.$refs.dimensions.checkedCodes;
            let dims = [];
            var hasBlankDim = false;
            for(let i in dimsCode) {
                let obj = {codeName:i,codes:dimsCode[i].sort()};
                dims.push(obj);
                if(dimsCode[i].length===0) {
                    hasBlankDim = true;
                }
            }
            if(hasBlankDim) {
                messageTips('所有维度均不得为空',2);
                return false;
            }
            params.dims = JSON.stringify(dims);
            let metaColumns = this.$refs.dimensions.metaLeftsNames;
            let metaRows = this.$refs.dimensions.metaTopsNames;
            let fix = this.$refs.dimensions.fixedsNames;
            if(fix.length===0) {
                fix = this.dimData.fixeds;
            }
            if(metaColumns.length===0||metaRows.length===0) {
                messageTips('行或列至少要有一个维度',2);
                return false;
            }
            params.metaColumns = metaColumns.join('-');
            params.metaRows = metaRows.join('-');
            if(fix) {
                params.fix = fix.join('-');
            }
            
            params.sourceId = this.sourceId;
            
            if(!flag) {
                let stepParams = {
                    dims:dims,
                    metaColumns,
                    metaRows,
                    fix
                }
                if(this.steps.length>2) {
                    this.steps.shift();
                    this.steps.push(JSON.parse(JSON.stringify(stepParams)));
                } else {                
                    this.steps.push(JSON.parse(JSON.stringify(stepParams)));
                    this.currentStep+=1;
                }
            }
            
            setLocalItem('dimData_'+this.cubeId,{dims:dims,metaColumns,metaRows,fix,cubeId:this.cubeId,jobId:this.$route.query.jobId?this.$route.query.jobId:-1,sourceId:this.sourceId});
            this.spinning = true;
            this.$http.syncData(params).then(res=>{
                this.spinning = false;
                this.isTableChanged = false;
                if(!res.dataletBO) {
                    messageTips(res.message,1);
                    return false;
                }
                if(!res.dataletBO.cellBOs) {
                    this.showBlank = true;
                    this.hotRef = null;
                    this.cubeInfo = null;
                    return false;
                }
                let fixedLeft = params.metaColumns.split('-');
                let fixedTop = params.metaRows.split('-');
                let table = tableParse({
                    fixedColumnsLeft: fixedLeft.length,
                    fixedRowsTop:fixedTop.length,
                    values:res.dataletBO.cellBOs
                },this.isShowCode);
                this.commentCellsArr = [];
                this.tableCells = res.dataletBO.cellBOs;
                res.dataletBO.cellBOs.forEach((item,key)=>{
                    item.forEach((i,k)=>{
                        if(i&&(i.commentInner||i.commentOuter)) {
                            this.commentCellsArr.push(
                                {
                                    row:key,
                                    col:k,
                                    comment:{value:'对外：'+i.commentOuter+'\n对内：'+i.commentInner}
                                }
                            )
                            this.commentCells[key+'_'+k] = {
                                innerComment:i.commentInner,
                                outComment:i.commentOuter
                            }
                        }
                    })
                });
                if(res.dataletBO.selectDimensions.dimParentCodeList) {
                    let obj = {}
                    res.dataletBO.selectDimensions.dimParentCodeList.forEach(item=>{
                        obj = {...obj,...item}
                    });    
                    this.selectedCodes = obj;
                }
                this.tableData = JSON.parse(JSON.stringify(table));
                this.proTableData = JSON.parse(JSON.stringify(table.data));
                this.tableData.editable = true;
                let settings = createSettings(this.tableData,null,this.commentCellsArr);
                settings.rowHeaders = true;
                settings.afterSelectionEnd = this.afterTableSelectionEnd;
                let handsontableDom = this.$refs.handsontable;
                if(this.hotRef) {
                    this.hotRef.destroy();
                }
                this.hotRef = new Handsontable(handsontableDom, settings);
                setTimeout(()=>{
                    this.hotRef.render();

                    this.hotRef.selectCell(0,0);
                },10);
                return true;
            });
        },
        /**
         * 保存数据
         * isAauto,自动保存
         * onlySave
         */
        saveData(isAuto,onlySave) {
            if(!this.hotRef) {
                return false;
            }
            let data = this.hotRef.getData();
            let innerComments = [],outComments = [];
            data.forEach((item)=>{
                let arr1 = [],arr2 = [];
                item.forEach(()=>{
                     arr1.push(''),
                     arr2.push('');                   
                })
                innerComments.push(arr1);
                outComments.push(arr2)
            });

            for(let i in this.commentCells) {
                let arr = i.split('_');
                if(this.commentCells[i].innerComment) {
                    innerComments[arr[0]][arr[1]] = this.commentCells[i].innerComment
                }
                if(this.commentCells[i].outComment) {
                    outComments[arr[0]][arr[1]] = this.commentCells[i].outComment
                }
            }
            
            
            let params = {
                sheetId:this.sheetId,
                vals:data,
                innerComments,
                outComments,
                jobId:this.$route.query.jobId,
                sourceId:this.sourceId
            }
            this.spinning = true;
            return this.$http.saveData(params).then(res=>{
                this.isTableChanged = false;
                this.spinning = false;
                if(isAuto) {
                    return false;
                }
                if(res) {
                    if(res.success) {
                        messageTips(res.message,3);
                        this.editStatus = true;
                        if(!onlySave) {
                            this.goSync();
                        }                        
                    } else {
                        messageTips(res.message,1);
                    }
                }
                return res.success;
            })
        },
        undo() {
            if(!this.hotRef) {
                return false;
            }
            this.hotRef.undo();
        },
        redo() {
            if(!this.hotRef) {
                return false;
            }
            this.hotRef.redo();
        },
        exportCsv() {
            if(!this.hotRef) {
                return false;
            }
            let params = {
                sheetId:this.sheetId,
                sid:getCookie('bd_sid')
            }
            window.location.href = config.downloadData+'?'+objToUrl(params);
        },
        refresh() {
            if(!this.hotRef) {
                return false;
            }
            var that = this;
            this.$confirm({
                title: '提示',
                content: '此操作将会重新查询数据，未保存的数据将会丢失，是否继续？',
                okText:'继续',
                cancelText:'取消',
                centered:true,
                onOk() {
                    that.goSync();
                },
                onCancel() {},
            });
        },
        rotate() {
            if(!this.hotRef) {
                return false;
            }
            let metaLefts = this.$refs.dimensions.metaLeftsNames;
            let dimsCode = this.$refs.dimensions.checkedCodes;
            let rows = 1;
            for(let i in dimsCode) {
                if(metaLefts.indexOf(i)!==-1) {
                    rows = rows*dimsCode[i].length;
                }
            }
            if(rows>2550) {
                messageTips('不支持大于2550列的数据',2);
                return false;
            }
            var that = this;
            this.$confirm({
                title: '提示',
                content: '此操作将会重新查询数据，未保存的数据将会丢失，是否继续？',
                okText:'继续',
                cancelText:'取消',
                centered:true,
                onOk() {
                    let metaLefts = that.$refs.dimensions.metaLeftsNames;
                    let metaTops = that.$refs.dimensions.metaTopsNames;
                    let fixeds = that.$refs.dimensions.fixedsNames;
                    let dimsCode = that.$refs.dimensions.checkedCodes;
                    if(metaLefts.length===0||metaTops.length===0) {
                        messageTips('行或列至少要有一个维度',2);
                        return false;
                    }
                    that.dimData = {
                        metaLefts:metaTops,
                        metaTops:metaLefts,
                        fixeds:fixeds,
                        allDimensions:that.allDimensions,
                        checkedCodes:dimsCode
                    }
                    setTimeout(()=>{
                        that.goSync();
                    },20);
                },
                onCancel() {},
            });
        },

        selectDimCode(data) {
            let obj = {...this.dimData};
            obj.checkedCodes = data;
            this.dimData = {...obj};
        },
        dimensionDragEnd(data) {
            this.dimData = data;
        },

        showCommentDrawer() {
            if(!this.hotRef) {
                return false;
            }
            let area = this.hotRef.getSelected();
            if(!area) {
                messageTips('请选择要添加批注的单元格',2);
                return false;
            }
            Object.assign(this,{
                drawerVidsible:true,
                outComment:'',
                innerComment:''
            });
        },
        addComments() {
            let area = this.hotRef.getSelected();
            let cells = [];
            for(let i in this.commentCells) {
                let arr = i.split('_');
                cells.push({
                    row:arr[0],
                    col:arr[1],
                    comment:{value:'对外：'+this.commentCells[i].outComment+'\n对内：'+this.commentCells[i].innerComment}
                })
            }
            if((typeof area[0])=='object') {
                area.forEach(item=>{
                    for(let i=item[0];i<(item[2]+1);i++) {
                        for(let j = item[1];j<(item[3]+1);j++) {
                            cells.push({
                                row:i,
                                col:j,
                                comment:{value:'对外：'+this.outComment+'\n对内：'+this.innerComment}
                            })
                            this.commentCells[i+'_'+j] = {
                                outComment:this.outComment,
                                innerComment:this.innerComment
                            }
                        }
                    }
                })
            } else {
                for(let i=area[0];i<=area[2];i++) {
                    for(let j = area[1];j<(area[3]+1);j++) {
                        cells.push({
                            row:i,
                            col:j,
                            comment:{value:'对外：'+this.outComment+'\n对内：'+this.innerComment}
                        })
                        this.commentCells[i+'_'+j] = {
                            outComment:this.outComment,
                            innerComment:this.innerComment
                        }
                    }
                }
            }
            let settings = createSettings(this.tableData,null,cells);
            settings.rowHeaders = true;
            let handsontableDom = this.$refs.handsontable;
            if(this.hotRef) {
                this.hotRef.destroy();
            }
            this.hotRef = new Handsontable(handsontableDom, settings); 
            this.drawerVidsible = false;

        },
        submitJob() {
            let editable = this.$route.query.editable;
            if(editable==='2') {
                this.$http.inputs_get_submitJob2({jobId:this.$route.query.jobId}).then(res=>{
                    if(res.success) {
                        messageTips(res.message,3);
                    } else {
                        messageTips(res.message,1);
                    }
                })
            } else {
                this.$http.inputs_get_submitJob({jobId:this.$route.query.jobId}).then(res=>{
                    if(res.success) {
                        messageTips(res.message,3);
                    } else {
                        messageTips(res.message,1);
                    }
                })
            }            
        },
        setTreeData(arr,pid) {
            let result = [];
            arr.forEach(item=>{
                if(item.pid===pid) {
                    item.children = this.setTreeData(arr,item.cubeId);
                    result.push(item);
                }
            })
            return result;
        },
        initData() {
            this.selectedCodes = {};//先把选中维度的父级数据清空
            return this.$http.getUserInfo().then(res=>{
                if(res&&res.userInfo&&res.userInfo.metaCubeList) {
                    // let arr = setCube();
                    this.treeData = this.setTreeData(res.userInfo.metaCubeList,0);
                    if(this.cubeId) {
                        res.userInfo.metaCubeList.some(item=>{
                            if(item.cubeId===this.cubeId) {
                                this.expandedKeys = [item.pid];
                                return true;
                            }
                        });
                    }                
                    return res.userInfo.metaCubeList;
                }
            }).then(res=>{
                if(res) {
                    let cubeId,cubeName;
                    if(res[0].children&&res[0].children[0]) {
                        cubeId = res[0].children[0].cubeId;
                        cubeName = encodeURI(res[0].children[0].cubeNameZh);
                    } else {
                        cubeId = res[0].cubeId;
                        cubeName =encodeURI(res[0].cubeNameZh);
                    }
                    if(!this.$route.query.cubeId) {                    
                        this.expandedKeys = [res[0].cubeId];
                        this.$router.push({path:'/inputs_data',query:{cubeId,cubeName}});
                    } else {
                        this.getDimensions(this.cubeId).then(()=>{
                            let dimData = getLocalItem('dimData_'+this.cubeId);
                            if(dimData) {
                                this.sourceId = dimData.sourceId;
                                // &&this.$route.query.jobId==dimData.jobId
                                if(this.cubeId==dimData.cubeId) {
                                    let checkedCodes = {}
                                    dimData.dims.forEach(item=>{
                                        checkedCodes[item.codeName] = item.codes;
                                    });
                                    let obj = JSON.parse(JSON.stringify(this.dimData))
                                    Object.assign(obj,{
                                        checkedCodes,
                                        metaLefts:dimData.metaColumns,
                                        metaTops:dimData.metaRows,                
                                        fixeds:dimData.fix,
                                    });
                                    this.dimData = obj;
                                    setTimeout(()=>{
                                        this.goSync();
                                    },50);
                                } else {
                                    delLocalItem('dimData_'+this.cubeId);
                                }
                            }                            
                        })
                    }
                }
                return res;
            });
        },
        filterFn() {
            this.filterVisible = Math.random();
        },

        windowApply(data) {
            if(data.name==='filterApply') {
                this.syncParams.s1 = data.s1;
                this.goSync(true);
            }
        },

        
        canSync(flag) {
            if(flag) {
                var hasBlankDim = false;
                let dimsCode = this.$refs.dimensions.checkedCodes;
                for(let i in dimsCode) {
                    if(dimsCode[i].length===0) {
                        hasBlankDim = true;
                        break;
                    }
                }
                if(!hasBlankDim) {
                    this.goSync();
                }
            }            
        },
        afterTableSelectionEnd() {

            this.selectedArea = this.hotRef.getSelected();
            
        },
        preStep() {
            this.currentStep-=1;
            let stepParams = this.steps[this.currentStep];
            let checkedCodes = {}
            stepParams.dims.forEach(item=>{
                checkedCodes[item.codeName] = item.codes;
            });
            let obj = JSON.parse(JSON.stringify(this.dimData))
            Object.assign(obj,{
                checkedCodes,
                metaLefts:stepParams.metaColumns,
                metaTops:stepParams.metaRows,                
                fix:stepParams.fix,
            });
            this.dimData = obj;
            this.goSync(true);
        },
        nextStep() {
            this.currentStep+=1;
            let stepParams = this.steps[this.currentStep];
            let checkedCodes = {}
            stepParams.dims.forEach(item=>{
                checkedCodes[item.codeName] = item.codes;
            });
            let obj = JSON.parse(JSON.stringify(this.dimData))
            Object.assign(obj,{
                checkedCodes,
                metaLefts:stepParams.metaColumns,
                metaTops:stepParams.metaRows,                
                fix:stepParams.fix,
            });
            this.dimData = obj;
            this.goSync(true);
        },
        getSource() {
            this.$http.getSource({cubeId:this.cubeId}).then(res=>{
                if(res.list) {
                    this.sourceList = res.list;
                    if(!this.sourceId) {
                        this.sourceId = res.list[0]?res.list[0].sourceId:undefined;
                    }
                }
            });
        },
        showCode() {
            if(!this.hotRef) {
                return false;
            }
            this.isShowCode = !this.isShowCode;
            let table = tableParse({
                fixedColumnsLeft: this.tableData.fixedColumnsLeft,
                fixedRowsTop:this.tableData.fixedRowsTop,
                values:this.tableCells
            },this.isShowCode);
            this.commentCellsArr = [];
            this.tableCells.forEach((item,key)=>{
                item.forEach((i,k)=>{
                    if(i&&i.commentOuter) {
                        this.commentCellsArr.push(
                            {
                                row:key,
                                col:k,
                                comment:{value:i.commentOuter}
                            }
                        )
                    }
                })
            });
            table.editable = true;
            let settings = createSettings(table,this.getIndicotor,this.commentCellsArr);
            settings.afterSelectionEnd = this.afterTableSelectionEnd;
            let handsontableDom = this.$refs.handsontable;
            if(this.hotRef) {
                this.hotRef.destroy();
            }
            this.hotRef = new Handsontable(handsontableDom, settings);            
            this.hotRef.render();
            // this.hotRef.selectCell(0,0);
        },
    },
    mounted() {
        this.initData().then(()=>{
            this.getSource();
        });
        
        window.onresize = ()=>{
            if(this.hotRef) {
                this.resizeTable();
            }
        }

        

        window.addEventListener('keyup',(e)=>{
            if(e.ctrlKey&&e.shiftKey&&e.keyCode==83) {
                if(this.hotRef&&!this.spinning) {
                    this.saveData();
                }
            }
        })
        
    }
}
</script>

<style lang="scss">
    @import '../../scss/platform.scss';
</style>