<template>
    <a-modal v-model="filterVisible" title="我的收藏夹" :footer="null" :width="400" :centered="true">
        
        <div class="option">
            <div class="h2">数据集名称：</div>
            <a-input v-model="fileName" />
        </div>
        <div class="option">
            <div class="h2">目录：</div>
            <a-tree-select
                v-model="parentId"
                style="width: 100%"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="folders"
                placeholder="请选择目录"
                tree-default-expand-all
            >
            </a-tree-select>
        </div>
        <div class="btns">
            <a-button type="primary" ghost @click="cancle">取消</a-button>
            <a-button type="primary" @click="goApply">确定</a-button>
        </div>
    </a-modal>
</template>

<script>
import { messageTips } from '../../../until/tools';
export default {
    name:'Collections',   
    props:['visible','sheetId'],
    data() {
        return {
            filterVisible:false,         
            fileName:'',
            parentId:0,
            folders:[],
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.filterVisible = true;
                this.fileName = '';
                this.folders = [];
                this.$http.user_collection_get_allFolder().then(res=>{
                    if(res&&res.list) {
                        this.folders = this.setFolderTree(res.list,0,[]);
                        this.folders.unshift({key:0,value:0,title:'默认目录'});
                    } else {
                        this.folders.unshift({key:0,value:0,title:'默认目录'});
                    }
                });
            } else {
                this.filterVisible = false;
            }
        }
    },
    methods:{
        cancle() {
            this.filterVisible = false;
        },
        goApply() {
            if(this.fileName==='') {
                messageTips('请输入文件名',2);
                return false;
            }
            this.$emit('goCollection',{name:this.fileName,parentId:this.parentId});
            this.filterVisible = false;
        },
        setFolderTree(arr,pid,result) {
            arr.forEach((item)=>{
                item.value = item.collectionCode;
                item.key = item.collectionCode;
				item.title = item.collectionName;
                if(item.parentCode===pid) {
                    if(!item.children) {
                        item.children = [];
                    }
                    item.children = this.setFolderTree(arr,item.collectionCode,item.children);
                    result.push(item);
                }
            });
            return result;
        }
    }
}
</script>

<style lang="scss" scoped>
.h2 {
    padding-bottom: 5px;
    font-weight: bold;
}
.option {
    margin-bottom: 20px;
}
</style>