<template>
    <div v-show="visibleMap" class="map-wrap">
        <div class="title">地图
            <a-icon type="close" @click="handleCancel" />
            <a-select style="margin-left:20px;" :dropdownMatchSelectWidth="true" v-model="selectedDimCode[item.codeName]" v-for="(item,key) in selectedDims" :key="key+randomVal" @change="e=>{changeDimension(e,item.codeName)}">
                <a-select-option v-for="i in item.list" :value="i.code" :key="i.code">{{i.name}}</a-select-option>
            </a-select>
        </div>
        <div class="g-map" id="amapContainer" ref="map"></div>

        <div class="time-line">
            <a-tabs
            :default-active-key="selectedTime"
            :tab-position="'top'"
            @prevClick="callback"
            @nextClick="callback"
            @change="changeTime"
            >
                <a-tab-pane v-for="(item,key) in times" :key="item.code">
                    <span slot="tab">
                        <div class="circle">
                            <div class="dot"></div>
                        </div>
                        <div class="item-name">{{item.name}}</div>
                        <div class="left-line" v-if="key!==0"></div>
                        <div class="right-line" v-if="key<times.length-1"></div>
                    </span>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>

<script>
import config from '../../../until/configs';

export default {
    props:['visible','mapData'],
    data() {
        return {
            visibleMap:false,
            times:[],
            regions:[],
            selectedTime:'',
            zoom: 5,
            center: [108.922923, 34.175807],
            mapHandle:null,
            provinceDistrict:null,
            cityDistrict:null,
            countyDistrict:null,
            selectedDims:[],
            selectedDimCode:{},
            textMarkers:[],
            randomVal:0
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.visibleMap = true;
                let times = [];
                this.mapData.cellBOs[0].forEach((item,key)=>{
                    if(key>=this.mapData.metaLefts.length) {
                        times.push({code:item.obj,name:item.showValue});
                    }
                });
                this.times = [...times];
                this.selectedTime = this.times[0].code;
                let selectedDims = {};
                this.mapData.cellBOs.forEach((item,key)=>{
                    if(key>0) {
                        item.some((i,k)=>{
                            if(k<this.mapData.metaLefts.length) {
                                if(this.mapData.metaLefts.indexOf(i.cellType)!==-1) {
                                    if(!selectedDims[i.cellType]) {
                                        selectedDims[i.cellType] = []
                                    }
                                    selectedDims[i.cellType].push({code:i.obj,name:i.showValue,adcode:i.adcode?i.adcode:null});
                                }
                            } else {
                                return false;
                            }
                        })
                    }
                });

                this.selectedDims = [];
                this.selectedDimCode = {}
                for(let i in selectedDims) {
                    let list = this.unique(selectedDims[i]);
                    if(i==='REGION') {
                        this.regions = list;
                    } else {
                        this.selectedDims.push({codeName:i,list});
                        this.selectedDimCode[i] = list[0].code||[];
                    }
                }

                let mapDatas = this.getMapDatas();
                this.drawBounds(mapDatas);
            }
        }
    },
    mounted() {
        
    },
    methods:{
        unique(arr) {
            let codes = [];
            let result = [];
            arr.forEach(item=>{
                if(codes.indexOf(item.code)===-1) {
                    codes.push(item.code);
                    result.push(item);
                }
            })
            return result;
        },
        changeTime(value) {
            this.selectedTime = value;
            let mapDatas = this.getMapDatas();
            this.drawBounds(mapDatas);
        },
        getMapDatas() {
            let targetKeys = [];
            this.regions.forEach(item=>{
                let key = '';
                this.mapData.metaLefts.forEach(i=>{
                    if(i==='REGION') {
                        key+=i+'_'+item.code+'-'
                    } else {
                        key+=i+'_'+this.selectedDimCode[i]+'-'
                    }
                });
                key+='TIME_'+this.selectedTime;
                targetKeys.push(key)
            });
            
            let data = [];
            this.mapData.cellBOs.forEach((item,key)=>{
                if(key>0) {
                    item.forEach((i,k)=>{
                        if(k>=this.mapData.metaLefts.length) {
                            if(targetKeys.indexOf(i.cellPid)!==-1) {
                                let result = {}
                                let arr = i.leftCellPid.split('-');
                                let obj = {};
                                arr.forEach(e=>{
                                    let a = e.split('_');
                                    obj[a[0]] = a[1];
                                })
                                let objs = this.getAdcode(obj.REGION);
                                result.adcode = objs.adcode;
                                result.name = objs.name;
                                result.showValue = i.showValue;
                                data.push(result);
                            }
                        }
                    })
                }
            });
            return [...data];
        },
        changeDimension(e,codeName) {
            this.selectedDimCode[codeName] = e;
            let mapDatas = this.getMapDatas();
            this.drawBounds(mapDatas);
            this.randomVal = Math.random();
        },
        getAdcode(code) {
            let obj;
            this.regions.some(item=>{
                if(item.code===code) {
                    obj = item;
                    return true;
                }
            });
            return obj;
        },
        handleCancel() {
            this.visibleMap = false;
            this.mapHandle = null;
        },
        callback(val) {
            console.log(val);
        },
        drawBounds(dataList) {
            let gaodeMapUrl = config.gaodeMapUrl;
            const getA = new Promise((resolve) => {
                // 如果已加载直接返回
                if(typeof window.AMap !== "undefined") {
                    resolve(window.AMap);
                    return true;
                }
                // 百度地图异步加载回调处理
                window.onBMapCallback = function () {
                    resolve(window.AMap);
                };            
                // 插入script脚本
                let scriptNode = document.createElement("script");
                scriptNode.setAttribute("type", "text/javascript");
                scriptNode.setAttribute("src", gaodeMapUrl);
                document.body.appendChild(scriptNode);            
                return true;
            });

            const getB = new Promise((resolve) => {
                // 如果已加载直接返回
                if(typeof window.AMapUI !== "undefined") {
                    resolve(window.AMapUI);
                    return true;
                }          
                // 插入script脚本
                let scriptNode = document.createElement("script");
                scriptNode.setAttribute("type", "text/javascript");
                scriptNode.setAttribute("src", '//webapi.amap.com/ui/1.1/main.js?v=1.1.1');
                document.body.appendChild(scriptNode);            
                return true;
            });

            Promise.all([getA, getB]).then(()=>{
                if(!this.mapHandle) {
                    this.mapHandle = new window.AMap.Map('amapContainer', {
                        zoom:5,//级别
                        center: [108.922923, 34.175807],//中心点坐标
                        viewMode:'3D'//使用3D视图
                    });
                }                
                
                

                this.provinceDistrict = new window.AMap.DistrictSearch({
                    extensions: 'all',
                    level: 'province'
                });

                this.cityDistrict = new window.AMap.DistrictSearch({
                    extensions: 'all',
                    level: 'city'
                });

                this.countyDistrict = new window.AMap.DistrictSearch({
                    extensions: 'all',
                    level: 'district'
                });

                this.textMarkers.forEach(item=>{
                    item.text.setMap(null);
                });
                this.textMarkers = [];

                var maxVal = this.getMaxVal([...dataList]);
                dataList.forEach(item=>{
                    let functionName;
                    if(item.adcode.substring(2,6)==='0000') {
                        functionName = this.provinceDistrict;
                    } else if(item.adcode.substring(4,6)==='00') {
                        functionName = this.cityDistrict;
                    } else {
                        functionName = this.countyDistrict;
                    }
                    let mapPolyArr = this.mapHandle.getAllOverlays('polygon')
                    // 定义一个空数组用来存放需要删除的覆盖物实例。
                    let arr = []
                    for (let i = 0; i < mapPolyArr.length; i++) {
                        // 将需要删除的项添加到空数组里
                        arr.push(mapPolyArr[i])
                    }
                    // 移除覆盖物
                    this.mapHandle.remove(arr);

                    functionName.search(item.adcode, (status, result)=> {
                        // 获取朝阳区的边界信息
                        var bounds = result.districtList[0].boundaries
                        var polygons = []
                        if (bounds) {
                            for (var i = 0, l = bounds.length; i < l; i++) {
                            //生成行政区划polygon
                                var polygon = new window.AMap.Polygon({
                                    map: this.mapHandle,
                                    strokeWeight: 1,
                                    path: bounds[i],
                                    fillOpacity: 0.7,
                                    fillColor: this.getColor(maxVal,item.showValue),
                                    strokeColor: '#fff000'
                                });
                                polygon.on('click',e=>{
                                    this.setText(item,e.lnglat);
                                })
                                polygons.push(polygon)
                            }
                            
                            // 地图自适应
                            this.mapHandle.setFitView();
                        }
                    })
                }); 

                
            })            
        },

        setText(item,lnglat) {            
            var text = new window.AMap.Text({
                text:item.name+'：'+item.showValue,
                anchor:'center', // 设置文本标记锚点
                cursor:'pointer',
                style:{
                    'background-color': 'rgba(255,255,255,1)',
                    'text-align': 'center',
                    'font-size': '12px',
                    'color': 'blue'
                },
                position: lnglat
            });
            
            this.textMarkers.forEach((i)=>{
                if(i.name===item.name) {
                    i.text.setMap(null);
                }
            });
            text.setMap(this.mapHandle);
            this.textMarkers.push({text,name:item.name});
        },

        getMaxVal(arr) {
            arr.sort(function(a,b){
                return parseInt(a.showValue) - parseInt(b.showValue);
            })
            return parseInt(arr.pop().showValue);
        },
        getColor(max,value) {
            let colorNum = 255-Math.ceil(parseInt(value)/max*255);
            return 'rgb(255,'+colorNum+','+colorNum+')';
        }
    }
}
</script>

<style lang="scss">
@import '../../../scss/_variables.scss';
.map-wrap {
    position: fixed;
    width:100%;
    height:100%;
    left:0;
    top:0;
    background:#fff;
    z-index: 999;
    
    .title {
        height:55px;
        line-height: 55px;
        padding:0 10px;
        border-bottom: 1px solid #ddd;
        .anticon-close {
            float:right;
            line-height: 55px;
            cursor: pointer;
        }
    }

    .g-map {
        height:calc(100% - 105px);
    }
    .time-line {
        height:50px;
        width:100%;
        background: #fff;
        overflow: hidden;
        text-align: center;
        .ant-tabs-tab {
            position: relative;
            margin-right:0;
        }
        .circle {
            width:12px;
            height:12px;
            border-radius: 50%;
            border:1px solid #ddd;
            margin:0 auto;
            background:#fff;
            position: relative;
            z-index: 10;
            .dot {
                width:6px;
                height:6px;
                background:$primary-color;
                border-radius: 50%;
                margin:0 auto;
                position: relative;
                top:2px;
                display: none;
            }
        }
        .item-name {
            color:$primary-color;
            padding:0;
            white-space: normal;                
        }
        .ant-tabs-nav .ant-tabs-tab-active {
            .dot {
                display: block;
            }
        }
        
        .left-line {
            width:50%;
            height:2px;
            background:#ddd;
            position: absolute;
            top:17px;
            left:0;
        }
        .right-line {
            width:50%;
            height:2px;
            background:#ddd;
            position: absolute;
            top:17px;
            right:0;
        }
    }
}
</style>