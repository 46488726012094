<template>
    <a-modal v-model="filterVisible" title="高亮显示" :footer="null" :centered="true">
        <div class="condition">
            <div class="h2">条件</div>
            <a-row :gutter="[16,16]">
                <a-col :span="24">
                    <a-select :default-value="0" v-model="selectedConditions" style="width: 100%">
                        <a-select-option :value="item.value" :key="item.value" v-for="item in conditions">
                            {{item.name}}
                        </a-select-option>
                    </a-select>
                </a-col>
            </a-row>

            <a-row :gutter="[16,16]">
                <a-col :span="11">
                    <div class="label">A:</div> <a-input-number style="width:170px;" v-model="numberA" :disabled="selectedConditions===0" />
                </a-col>
                <a-col :span="2"> </a-col>
                <a-col :span="11">
                    <div class="label">B:</div> <a-input-number style="width:170px;" v-model="numberB" :disabled="selectedConditions!==7" />
                </a-col>
            </a-row>

            <div class="h2">样式</div>
            <div class="styles">
                <a-row :gutter="[16,16]">
                    <a-col :span="8">
                        <div class="label">单元格背景：</div>
                        <div class="color-select">
                            <colorPicker v-model="selectedBgColor" />
                        </div>
                    </a-col>
                    <a-col :span="8">                    
                        <div class="label">字体颜色：</div>
                        <div class="color-select">
                            <colorPicker v-model="selectedColor" />
                        </div>
                    </a-col>
                    <a-col :span="8">                    
                        <div class="label" style="width:80px">字体大小：</div>
                        <a-input-number style="width:60px" v-model="selectedFontSize" size="small" />
                    </a-col>
                </a-row>

                <a-row :gutter="[16,16]">
                    <a-col :span="15">
                        <a-checkbox-group v-model="selectedStyles">
                            <a-checkbox  v-for="item in fontStyles" :key="item.id" :style="item.style" :value="item.id">{{item.name}}</a-checkbox>
                        </a-checkbox-group>
                    </a-col>
                </a-row>
            </div>
        </div>

        <div class="btns">
            <a-button type="primary" ghost @click="cancle">取消</a-button>
            <a-button type="primary" @click="goApply">应用</a-button>
        </div>
    </a-modal>
</template>

<script>
import { messageTips } from '../../../until/tools';
export default {
    name:'HightLight',
    props:['visible','s5'],
    data() {
        return {
            filterVisible:false,
            conditions : [
                {value:0,name:'无'},
                {value:1,name:'等于A'},
                {value:2,name:'不等于A'},
                {value:3,name:'大于A'},
                {value:4,name:'小于A'},
                {value:5,name:'大于或等于A'},
                {value:6,name:'小于或等于A'},
                {value:7,name:'A,B之间'},
                {value:8,name:'最大A'},
                {value:9,name:'最小A'}
            ],
            selectedConditions:0,
            selectedFontSize:12,
            numberA:null,
            numberB:null,
            selectedBgColor:'#ffffff',
            selectedColor:'#ff0000',
            selectedStyles:[],
            fontStyles:[
                {id:'bolder',name:'加粗',style:{fontWeight:'bold'}},
                {id:'italic',name:'斜体',style:{fontStyle:'italic'}},
                {id:'underline',name:'下划线',style:{textDecoration:'underline'}}
            ]
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.filterVisible = true;
            } else {
                this.filterVisible = false;
            }
        },
        s5(value) {
            this.selectedConditions = 0;
            this.selectedFontSize = 12;
            this.numberA = null;
            this.numberB = null;
            this.selectedBgColor = '#ffffff';
            this.selectedColor = '#ff0000';
            this.selectedStyles = [];
            if(value) {
                if(value.showBackColor) {
                    this.selectedBgColor = value.showBackColor;
                }
                if(value.showFontStyle) {
                    let fontStyle = JSON.parse(value.showFontStyle);
                    this.selectedColor = fontStyle.color;
                    this.selectedFontSize = parseInt(fontStyle.fontSize);
                    this.selectedStyles = fontStyle.fontStyles;
                }
                if(value.dealStr) {
                    let arr1 = value.dealStr.split(',');
                    let arr2 = arr1[0].split('_');
                    if(arr2[1]) {
                        this.numberA = parseInt(arr2[1]);
                    }
                    switch (arr2[0]) {
                        case 'eq':
                            this.selectedConditions = 1;
                            break;
                        case 'neq':
                            this.selectedConditions = 2;
                            break;
                        case 'gt':
                            this.selectedConditions = 3;
                            break;
                        case 'lt':
                            this.selectedConditions = 4;
                            break;
                        case 'gte':
                            this.selectedConditions = 5;
                            break;
                        case 'lte':                        
                            if(arr1[1]) {
                                this.selectedConditions = 7;
                                let arr3 = arr1[1].split('_');
                                this.numberB = parseInt(arr3[1]);
                            } else {
                                this.selectedConditions = 6;
                            }
                            break;
                        case 'max':
                            this.selectedConditions = 8;
                            break;
                        case 'min':
                            this.selectedConditions = 9;
                            break;
                        default:
                            break;
                    }
                }                
            }
        }
    },
    methods:{
        cancle() {
            this.filterVisible = false;
        },
        goApply() {
            let s5 = this.setConditions();
            if(!s5) {
                return false;
            }
            this.$emit('highLight',{name:'highLight',s5});
            this.filterVisible = false;
        },
        
        setConditions() {
            var str = null;
            if(this.selectedConditions) {
                if(this.selectedConditions!==7) {
                    if(!this.numberA) {
                        messageTips('请输入参数A',2);
                        return false;
                    }
                }else {
                    if(!this.numberA) {
                        messageTips('请输入参数A',2);
                        return false;
                    }
                    if(!this.numberB) {
                        messageTips('请输入参数B',2);
                        return false;
                    }
                }
            }
            switch(this.selectedConditions) {
                case 1:
                    str = 'eq_'+this.numberA;
                break;
                case 2:
                    str = 'neq_'+this.numberA;
                break;
                case 3:
                    str = 'gt_'+this.numberA;
                break;
                case 4:
                    str = 'lt_'+this.numberA;
                break;
                case 5:
                    str = 'gte_'+this.numberA;
                break;
                case 6:
                    str = 'lte_'+this.numberA;
                break;
                case 7:
                    str = 'gte_'+this.numberA+','+'lte_'+this.numberB;
                break;
                case 8:
                    str = 'max_'+this.numberA;
                break;
                case 9:
                    str = 'min_'+this.numberA;
                break;
                default:break;
            }
            var fontStyles = {
                color:this.selectedColor,
                fontStyles:this.selectedStyles,
                fontSize:this.selectedFontSize+'px'
            }
            var stringStyles = JSON.stringify(fontStyles);
            let s5 = {
                showBackColor:this.selectedBgColor,
                showFontStyle:stringStyles,
                dealStr:str
            };
            return s5;
        },
    }
}
</script>

<style lang="scss" scoped>
.condition {
    .h2 {
        font-weight: bold;
        line-height: 30px;
    }
    .label {
        display: inline-block;
        line-height: 32px;
        width:30px;
    }
    .styles {
        .label {
            display: block;
            line-height: 24px;
            width:84px;
            float:left;
            text-align: right;
        }
        .color-select {
            width:24px;
            height:24px;
            border:1px solid #ddd;
            display: inline-block;
            padding:1px;            
        }
        
    }
    
}
</style>
{"newSheetId":"1635471803000s3.5741227279335575","s1":{"dealStr":"empty,0,gt_100000"},"s2":{"method":"","type":""},"s3":{"index8020":"0","fontStyle8020":"","bgColor8020":""},"s4":{"orderStr":"","index":""},"s5":{"showBackColor":"#fff","showFontStyle":"{\"color\":\"#f00\",\"fontStyles\":[\"bolder\"],\"fontSize\":12}","dealStr":"gt_60000"},"cubeId":"891","sheetId":"CD6FB0C2BF1047668BF3638C63F9D034","dims":"[{\"codeName\":\"timeCode\",\"codes\":[\"2020010101\",\"2019010101\",\"2018010101\",\"2017010101\",\"2016010101\",\"2015010101\",\"2014010101\",\"2013010101\",\"2012010101\",\"2011010101\"]},{\"codeName\":\"indicatorCode\",\"codes\":[\"01010101\",\"01010102\",\"01010103\"]}]","metaColumns":"indicatorCode","metaRows":"timeCode","sid":"CD3E7ED753CE1B00FA7D1034528D134F"}