<template>
    <a-modal v-model="filterVisible" title="设置单元格格式" :footer="null" :centered="true">
        <div class="variables">
            <div class="h2">选择变量：<a-checkbox @click="selectAll" :indeterminate="checkedList.length>0&&checkedList.length<list.length" :checked="checkedList.length===list.length&&list.length>0">全选</a-checkbox></div>
            <div class="list">
                <a-checkbox-group v-model="checkedList">
                    <div class="check-item" v-for="item in list" :key="item.index">
                        <a-checkbox :value="item.index">{{item.key}}</a-checkbox>
                    </div>
                </a-checkbox-group>
            </div>
        </div>

        
        <div class="condition">
            <div class="h2">格式设置</div>
            <div class="item">
                <div class="label">小数位数：</div><a-input-number v-model="floatNum" :min="0" size="small" />
                <div class="clear"></div>
            </div>
            <div class="item">
                <div class="label">货币格式：</div>
                <a-radio-group v-model="moneyFormat" @change="changeMoneyFormat">
                    <a-radio value="none">无</a-radio>
                    <a-radio value="zh-CN">￥</a-radio>
                    <a-radio value="en-US">$</a-radio>
                </a-radio-group>
                <div class="clear"></div>
            </div>
            <div class="item">
                <a-checkbox :checked="percentFormat" @change="changePercent">百分比格式</a-checkbox>
            </div>
            <div class="item">
                <a-checkbox :checked="thousandsSeparator" @change="changeThousandsSeparator">使用千位分隔符（,）</a-checkbox>
            </div>
        </div>
        <div class="btns">
            <a-button type="primary" ghost @click="cancel">取消</a-button>
            <a-button type="primary" @click="goApply">应用</a-button>
        </div>
    </a-modal>
</template>

<script>
import { messageTips } from '../../../until/tools';
export default {
    name:'CellFormat',
    props:['visible','sheetId'],
    data() {
        return {
            filterVisible:false,
            list:[],
            checkedList:[],
            floatNum:2,
            moneyFormat:'none',
            percentFormat:false,
            thousandsSeparator:true
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.filterVisible = true;
                this.floatNum = 2;
                this.moneyFormat = 'none';
                this.percentFormat = false;
                this.thousandsSeparator = true;
                this.checkedList = [];
                this.list = [];
                this.$http.getVars({sheetId:this.sheetId}).then(res=>{
                    if(res) {
                        this.list = res;
                    }
                });
            } else {
                this.filterVisible = false;
            }
        }
    },
    methods:{
        cancel() {
            this.filterVisible = false;
        },
        selectAll() {
            if(this.checkedList.length === this.list.length) {
                this.checkedList = [];
            } else {
                this.list.forEach(item=>{
                    let index = this.checkedList.indexOf(item.index);
                    if(index===-1) {
                        this.checkedList.push(item.index);
                    }
                });
            }
            
        },
        changePercent(e) {
            this.percentFormat = e.target.checked;
            if(e.target.checked) {
                this.moneyFormat = 'none';
            }
        },
        changeMoneyFormat(e) {
            if(e.target.value!=='none') {
                if(this.percentFormat) {
                    this.percentFormat = false;
                }
            }
        },
        changeThousandsSeparator(e) {
            this.thousandsSeparator = e.target.checked;
        },
        goApply() {
            if(this.checkedList.length===0) {
                messageTips('请选择变量',2);
                return false;
            }
            var params = {
				floatNum : this.floatNum,
				moneyFormat : this.moneyFormat,
				percentFormat : this.percentFormat,
				thousandsSeparator :this.thousandsSeparator,
				selectedRows:this.checkedList
            }
            this.$emit('cellFormat',{name:'cellFormat',data:params});
            this.filterVisible = false;
        },
        
        
    }
}
</script>

<style lang="scss" scoped>
    .h2 {
        font-weight: bold;
        .ant-checkbox-wrapper {
            font-weight:normal;
        }
    }
    .variables {
        .list {
            height:150px;
            border:1px solid #ddd;
            margin:10px 0;
            padding:5px 10px;
            overflow-y: auto;
            .check-item {
                padding:3px 0;
            }
        }
    }
    .condition {
        .h2 {
            margin-bottom: 10px;
        }
        padding-top:10px;
        .item {
            padding:3px 0;
            .label {
                float:left;
            }
        }
    }
</style>